import { Component } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CommonServiceService } from './Services/common-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'whatsapp_super_admin';
  ischeck:any
  pathUrl:any="/dashboard"
  ids:any
  constructor(public router: Router, public _service:CommonServiceService,private route: ActivatedRoute){}
  
  ngOnInit(): void {
    // this.ischeck=localStorage.getItem('logId'); 
    
    // this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {     
    //   this.pathUrl = event.url
    //   const isSubstringPresent = this.pathUrl.includes("/superadmin/business-user-detail");
    //   if(isSubstringPresent){
    //     this.pathUrl = "/superadmin/business-user-detail"
    //   }                 
    // });
  }

}

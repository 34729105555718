import { Injectable } from '@angular/core';
// import { log } from 'console';
import { BehaviorSubject } from 'rxjs';
import { CountryISO } from 'ngx-intl-tel-input';
@Injectable({
  providedIn: 'root'
})
export class TempDataStorageService {
  public notify = new BehaviorSubject<any>('');
  notifyObservable$ = this.notify.asObservable();
  IsMaintenanceStatus: any;
  

  constructor() {
 }
  public notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }

  getPlanStatus(packageData:any) {
    const expiryDate = new Date(packageData.expiryDate);
    const today = new Date();
    if (packageData.userActivePackage == 1) {
      if (packageData.iscancel == 1) {
       return  'Cancelled'
      } else if (expiryDate < today) {
        return 'UnPaid'
      } else {
        return 'Active'
      }
      
    } else { 
      return 'Deactive'
    } 
}

  paginationHandle(component: any) {
    let pageDataString = localStorage.getItem('PageNo');
    let pageData = pageDataString !== null ? JSON.parse(pageDataString) : null;
    if (pageData && pageData.status == 1 && pageData.page == component) {
      pageData.status = 0;
      localStorage.setItem('PageNo', JSON.stringify(pageData));
      return {
        skipnext: (pageData.pageNo - 1) * 10,
        page: pageData.pageNo,
        search: pageData.search
      };
    } else {
      localStorage.removeItem('PageNo');
      return null; // or any default value you want to return when there's no pagination data
    }
  }

  setPaginationValue(path:any,id:any,search:any) {
    localStorage.setItem('PageNo', JSON.stringify({ page: path, status: 0, pageNo: id,search: search}));
  }
  pageStatusTrue(status: any, page: any) { // this funtion work only PageNo in status 1 set value.
    let pageDataString = localStorage.getItem('PageNo');
    let pageData = pageDataString !== null ? JSON.parse(pageDataString) : null;
    if (pageData) {
      pageData.status = status; pageData.page = page;
      localStorage.setItem('PageNo', JSON.stringify(pageData));
    }
  }

  getFileType(url: any): string {
    // Define lists of common image and document extensions
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp', 'tiff'];
    const documentExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'html'];

    // Extract the file extension from the URL
    const extension = url.split('.').pop()?.toLowerCase();

    // Check if the extension is in the image extensions list
    if (extension && imageExtensions.includes(extension)) {
      return 'image';
    }

    // Check if the extension is in the document extensions list
    if (extension && documentExtensions.includes(extension)) {
      return 'document';
    }

    // If the extension does not match any known types
    return 'unknown';
  }
  packageType = [
    "days", "monthly", "yearly","quarterly"
  ];

  formatSizeUnits(bytes: any) {
    if (bytes >= 1073741824) {
      return (bytes = (bytes / 1073741824).toFixed(2) + ' GB');
    } else if (bytes >= 1048576) {
      return (bytes = (bytes / 1048576).toFixed(2) + ' MB');
    } else if (bytes >= 1024) {
      return (bytes = (bytes / 1024).toFixed(2) + ' KB');
    } else if (bytes > 1) {
      return (bytes = bytes + ' bytes');
    } else if (bytes == 1) {
      return (bytes = bytes + ' byte');
    } else {
      return (bytes = '0 bytes');
    }
    return bytes;
  }

  async compareTimeWithOtherCountry(IsMaintenanceStatus: any = 0, timeZone: string = 'UTC'): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        if (IsMaintenanceStatus.maintenanceStatus == 1) {
          if (IsMaintenanceStatus) {
            let startTime = new Date(`2024-02-16T${IsMaintenanceStatus.startTimeUTC}:39.906Z`);
            let endTime = new Date(`2024-02-16T${IsMaintenanceStatus.endTimeUTC}:39.906Z`);
            let options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', timeZone: timeZone, hour12: true };
            let StartT = new Intl.DateTimeFormat('en-US', options).format(startTime);
            let EndT = new Intl.DateTimeFormat('en-US', options).format(endTime);
            resolve(IsMaintenanceStatus.maintenanceText
              .replace('{{startTimeUTC}}', StartT)
              .replace('{{endTimeUTC}}', EndT));
          } else {
            resolve('');
          }
        } else {
          resolve('');
        }
      } catch (error) {
      }
    })
  }

  utc2 = [
    {
      id: 'AD',
      name: 'Andorra',
      timezones: ['Europe/Andorra'],
    },
    {
      id: 'AE',
      name: 'United Arab Emirates',
      timezones: ['Asia/Dubai'],
    },
    {
      id: 'AF',
      name: 'Afghanistan',
      timezones: ['Asia/Kabul'],
    },
    {
      id: 'AG',
      name: 'Antigua and Barbuda',
      timezones: ['America/Puerto_Rico'],
    },
    {
      id: 'AI',
      name: 'Anguilla',
      timezones: ['America/Puerto_Rico'],
    },
    {
      id: 'AL',
      name: 'Albania',
      timezones: ['Europe/Tirane'],
    },
    {
      id: 'AM',
      name: 'Armenia',
      timezones: ['Asia/Yerevan'],
    },
    {
      id: 'AO',
      name: 'Angola',
      timezones: ['Africa/Lagos'],
    },
    {
      id: 'AQ',
      name: 'Antarctica',
      timezones: [
        'Antarctica/Casey',
        'Antarctica/Davis',
        'Antarctica/Mawson',
        'Antarctica/Palmer',
        'Antarctica/Rothera',
        'Antarctica/Troll',
        'Asia/Riyadh',
        'Asia/Urumqi',
        'Pacific/Auckland',
        'Pacific/Port_Moresby',
      ],
    },
    {
      id: 'AR',
      name: 'Argentina',
      timezones: [
        'America/Argentina/Buenos_Aires',
        'America/Argentina/Catamarca',
        'America/Argentina/Cordoba',
        'America/Argentina/Jujuy',
        'America/Argentina/La_Rioja',
        'America/Argentina/Mendoza',
        'America/Argentina/Rio_Gallegos',
        'America/Argentina/Salta',
        'America/Argentina/San_Juan',
        'America/Argentina/San_Luis',
        'America/Argentina/Tucuman',
        'America/Argentina/Ushuaia',
      ],
    },
    {
      id: 'AS',
      name: 'American Samoa',
      timezones: ['Pacific/Pago_Pago'],
    },
    {
      id: 'AT',
      name: 'Austria',
      timezones: ['Europe/Vienna'],
    },
    {
      id: 'AU',
      name: 'Australia',
      timezones: [
        'Antarctica/Macquarie',
        'Australia/Adelaide',
        'Australia/Brisbane',
        'Australia/Broken_Hill',
        'Australia/Darwin',
        'Australia/Eucla',
        'Australia/Hobart',
        'Australia/Lindeman',
        'Australia/Lord_Howe',
        'Australia/Melbourne',
        'Australia/Perth',
        'Australia/Sydney',
      ],
    },
    {
      id: 'AW',
      name: 'Aruba',
      timezones: ['America/Puerto_Rico'],
    },
    {
      id: 'AX',
      name: 'Åland Islands',
      timezones: ['Europe/Helsinki'],
    },
    {
      id: 'AZ',
      name: 'Azerbaijan',
      timezones: ['Asia/Baku'],
    },
    {
      id: 'BA',
      name: 'Bosnia and Herzegovina',
      timezones: ['Europe/Belgrade'],
    },
    {
      id: 'BB',
      name: 'Barbados',
      timezones: ['America/Barbados'],
    },
    {
      id: 'BD',
      name: 'Bangladesh',
      timezones: ['Asia/Dhaka'],
    },
    {
      id: 'BE',
      name: 'Belgium',
      timezones: ['Europe/Brussels'],
    },
    {
      id: 'BF',
      name: 'Burkina Faso',
      timezones: ['Africa/Abidjan'],
    },
    {
      id: 'BG',
      name: 'Bulgaria',
      timezones: ['Europe/Sofia'],
    },
    {
      id: 'BH',
      name: 'Bahrain',
      timezones: ['Asia/Qatar'],
    },
    {
      id: 'BI',
      name: 'Burundi',
      timezones: ['Africa/Maputo'],
    },
    {
      id: 'BJ',
      name: 'Benin',
      timezones: ['Africa/Lagos'],
    },
    {
      id: 'BL',
      name: 'Saint Barthélemy',
      timezones: ['America/Puerto_Rico'],
    },
    {
      id: 'BM',
      name: 'Bermuda',
      timezones: ['Atlantic/Bermuda'],
    },
    {
      id: 'BN',
      name: 'Brunei',
      timezones: ['Asia/Kuching'],
    },
    {
      id: 'BO',
      name: 'Bolivia',
      timezones: ['America/La_Paz'],
    },
    {
      id: 'BQ',
      name: 'Caribbean Netherlands',
      timezones: ['America/Puerto_Rico'],
    },
    {
      id: 'BR',
      name: 'Brazil',
      timezones: [
        'America/Araguaina',
        'America/Bahia',
        'America/Belem',
        'America/Boa_Vista',
        'America/Campo_Grande',
        'America/Cuiaba',
        'America/Eirunepe',
        'America/Fortaleza',
        'America/Maceio',
        'America/Manaus',
        'America/Noronha',
        'America/Porto_Velho',
        'America/Recife',
        'America/Rio_Branco',
        'America/Santarem',
        'America/Sao_Paulo',
      ],
    },
    {
      id: 'BS',
      name: 'Bahamas',
      timezones: ['America/Toronto'],
    },
    {
      id: 'BT',
      name: 'Bhutan',
      timezones: ['Asia/Thimphu'],
    },
    {
      id: 'BW',
      name: 'Botswana',
      timezones: ['Africa/Maputo'],
    },
    {
      id: 'BY',
      name: 'Belarus',
      timezones: ['Europe/Minsk'],
    },
    {
      id: 'BZ',
      name: 'Belize',
      timezones: ['America/Belize'],
    },
    {
      id: 'CA',
      name: 'Canada',
      timezones: [
        'America/Cambridge_Bay',
        'America/Dawson',
        'America/Dawson_Creek',
        'America/Edmonton',
        'America/Fort_Nelson',
        'America/Glace_Bay',
        'America/Goose_Bay',
        'America/Halifax',
        'America/Inuvik',
        'America/Iqaluit',
        'America/Moncton',
        'America/Panama',
        'America/Phoenix',
        'America/Puerto_Rico',
        'America/Rankin_Inlet',
        'America/Regina',
        'America/Resolute',
        'America/St_Johns',
        'America/Swift_Current',
        'America/Toronto',
        'America/Vancouver',
        'America/Whitehorse',
        'America/Winnipeg',
      ],
    },
    {
      id: 'CC',
      name: 'Cocos Islands',
      timezones: ['Asia/Yangon'],
    },
    {
      id: 'CD',
      name: 'Democratic Republic of the Congo',
      timezones: ['Africa/Lagos', 'Africa/Maputo'],
    },
    {
      id: 'CF',
      name: 'Central African Republic',
      timezones: ['Africa/Lagos'],
    },
    {
      id: 'CG',
      name: 'Republic of the Congo',
      timezones: ['Africa/Lagos'],
    },
    {
      id: 'CH',
      name: 'Switzerland',
      timezones: ['Europe/Zurich'],
    },
    {
      id: 'CI',
      name: 'Ivory Coast',
      timezones: ['Africa/Abidjan'],
    },
    {
      id: 'CK',
      name: 'Cook Islands',
      timezones: ['Pacific/Rarotonga'],
    },
    {
      id: 'CL',
      name: 'Chile',
      timezones: ['America/Punta_Arenas', 'America/Santiago', 'Pacific/Easter'],
    },
    {
      id: 'CM',
      name: 'Cameroon',
      timezones: ['Africa/Lagos'],
    },
    {
      id: 'CN',
      name: 'China',
      timezones: ['Asia/Shanghai', 'Asia/Urumqi'],
    },
    {
      id: 'CO',
      name: 'Colombia',
      timezones: ['America/Bogota'],
    },
    {
      id: 'CR',
      name: 'Costa Rica',
      timezones: ['America/Costa_Rica'],
    },
    {
      id: 'CU',
      name: 'Cuba',
      timezones: ['America/Havana'],
    },
    {
      id: 'CV',
      name: 'Cabo Verde',
      timezones: ['Atlantic/Cape_Verde'],
    },
    {
      id: 'CW',
      name: 'Curaçao',
      timezones: ['America/Puerto_Rico'],
    },
    {
      id: 'CX',
      name: 'Christmas Island',
      timezones: ['Asia/Bangkok'],
    },
    {
      id: 'CY',
      name: 'Cyprus',
      timezones: ['Asia/Famagusta', 'Asia/Nicosia'],
    },
    {
      id: 'CZ',
      name: 'Czechia',
      timezones: ['Europe/Prague'],
    },
    {
      id: 'DE',
      name: 'Germany',
      timezones: ['Europe/Berlin', 'Europe/Zurich'],
    },
    {
      id: 'DJ',
      name: 'Djibouti',
      timezones: ['Africa/Nairobi'],
    },
    {
      id: 'DK',
      name: 'Denmark',
      timezones: ['Europe/Berlin'],
    },
    {
      id: 'DM',
      name: 'Dominica',
      timezones: ['America/Puerto_Rico'],
    },
    {
      id: 'DO',
      name: 'Dominican Republic',
      timezones: ['America/Santo_Domingo'],
    },
    {
      id: 'DZ',
      name: 'Algeria',
      timezones: ['Africa/Algiers'],
    },
    {
      id: 'EC',
      name: 'Ecuador',
      timezones: ['America/Guayaquil', 'Pacific/Galapagos'],
    },
    {
      id: 'EE',
      name: 'Estonia',
      timezones: ['Europe/Tallinn'],
    },
    {
      id: 'EG',
      name: 'Egypt',
      timezones: ['Africa/Cairo'],
    },
    {
      id: 'EH',
      name: 'Western Sahara',
      timezones: ['Africa/El_Aaiun'],
    },
    {
      id: 'ER',
      name: 'Eritrea',
      timezones: ['Africa/Nairobi'],
    },
    {
      id: 'ES',
      name: 'Spain',
      timezones: ['Africa/Ceuta', 'Atlantic/Canary', 'Europe/Madrid'],
    },
    {
      id: 'ET',
      name: 'Ethiopia',
      timezones: ['Africa/Nairobi'],
    },
    {
      id: 'FI',
      name: 'Finland',
      timezones: ['Europe/Helsinki'],
    },
    {
      id: 'FJ',
      name: 'Fiji',
      timezones: ['Pacific/Fiji'],
    },
    {
      id: 'FK',
      name: 'Falkland Islands',
      timezones: ['Atlantic/Stanley'],
    },
    {
      id: 'FM',
      name: 'Micronesia',
      timezones: [
        'Pacific/Guadalcanal',
        'Pacific/Kosrae',
        'Pacific/Port_Moresby',
      ],
    },
    {
      id: 'FO',
      name: 'Faroe Islands',
      timezones: ['Atlantic/Faroe'],
    },
    {
      id: 'FR',
      name: 'France',
      timezones: ['Europe/Paris'],
    },
    {
      id: 'GA',
      name: 'Gabon',
      timezones: ['Africa/Lagos'],
    },
    {
      id: 'GB',
      name: 'United Kingdom',
      timezones: ['Europe/London'],
    },
    {
      id: 'GD',
      name: 'Grenada',
      timezones: ['America/Puerto_Rico'],
    },
    {
      id: 'GE',
      name: 'Georgia',
      timezones: ['Asia/Tbilisi'],
    },
    {
      id: 'GF',
      name: 'French Guiana',
      timezones: ['America/Cayenne'],
    },
    {
      id: 'GG',
      name: 'Guernsey',
      timezones: ['Europe/London'],
    },
    {
      id: 'GH',
      name: 'Ghana',
      timezones: ['Africa/Abidjan'],
    },
    {
      id: 'GI',
      name: 'Gibraltar',
      timezones: ['Europe/Gibraltar'],
    },
    {
      id: 'GL',
      name: 'Greenland',
      timezones: [
        'America/Danmarkshavn',
        'America/Nuuk',
        'America/Scoresbysund',
        'America/Thule',
      ],
    },
    {
      id: 'GM',
      name: 'Gambia',
      timezones: ['Africa/Abidjan'],
    },
    {
      id: 'GN',
      name: 'Guinea',
      timezones: ['Africa/Abidjan'],
    },
    {
      id: 'GP',
      name: 'Guadeloupe',
      timezones: ['America/Puerto_Rico'],
    },
    {
      id: 'GQ',
      name: 'Equatorial Guinea',
      timezones: ['Africa/Lagos'],
    },
    {
      id: 'GR',
      name: 'Greece',
      timezones: ['Europe/Athens'],
    },
    {
      id: 'GS',
      name: 'South Georgia and the South Sandwich Islands',
      timezones: ['Atlantic/South_Georgia'],
    },
    {
      id: 'GT',
      name: 'Guatemala',
      timezones: ['America/Guatemala'],
    },
    {
      id: 'GU',
      name: 'Guam',
      timezones: ['Pacific/Guam'],
    },
    {
      id: 'GW',
      name: 'Guinea-Bissau',
      timezones: ['Africa/Bissau'],
    },
    {
      id: 'GY',
      name: 'Guyana',
      timezones: ['America/Guyana'],
    },
    {
      id: 'HK',
      name: 'Hong Kong',
      timezones: ['Asia/Hong_Kong'],
    },
    {
      id: 'HN',
      name: 'Honduras',
      timezones: ['America/Tegucigalpa'],
    },
    {
      id: 'HR',
      name: 'Croatia',
      timezones: ['Europe/Belgrade'],
    },
    {
      id: 'HT',
      name: 'Haiti',
      timezones: ['America/Port-au-Prince'],
    },
    {
      id: 'HU',
      name: 'Hungary',
      timezones: ['Europe/Budapest'],
    },
    {
      id: 'ID',
      name: 'Indonesia',
      timezones: [
        'Asia/Jakarta',
        'Asia/Jayapura',
        'Asia/Makassar',
        'Asia/Pontianak',
      ],
    },
    {
      id: 'IE',
      name: 'Ireland',
      timezones: ['Europe/Dublin'],
    },
    {
      id: 'IL',
      name: 'Israel',
      timezones: ['Asia/Jerusalem'],
    },
    {
      id: 'IM',
      name: 'Isle of Man',
      timezones: ['Europe/London'],
    },
    {
      id: 'IN',
      name: 'India',
      timezones: ['Asia/Kolkata'],
    },
    {
      id: 'IO',
      name: 'British Indian Ocean Territory',
      timezones: ['Indian/Chagos'],
    },
    {
      id: 'IQ',
      name: 'Iraq',
      timezones: ['Asia/Baghdad'],
    },
    {
      id: 'IR',
      name: 'Iran',
      timezones: ['Asia/Tehran'],
    },
    {
      id: 'IS',
      name: 'Iceland',
      timezones: ['Africa/Abidjan'],
    },
    {
      id: 'IT',
      name: 'Italy',
      timezones: ['Europe/Rome'],
    },
    {
      id: 'JE',
      name: 'Jersey',
      timezones: ['Europe/London'],
    },
    {
      id: 'JM',
      name: 'Jamaica',
      timezones: ['America/Jamaica'],
    },
    {
      id: 'JO',
      name: 'Jordan',
      timezones: ['Asia/Amman'],
    },
    {
      id: 'JP',
      name: 'Japan',
      timezones: ['Asia/Tokyo'],
    },
    {
      id: 'KE',
      name: 'Kenya',
      timezones: ['Africa/Nairobi'],
    },
    {
      id: 'KG',
      name: 'Kyrgyzstan',
      timezones: ['Asia/Bishkek'],
    },
    {
      id: 'KH',
      name: 'Cambodia',
      timezones: ['Asia/Bangkok'],
    },
    {
      id: 'KI',
      name: 'Kiribati',
      timezones: ['Pacific/Kanton', 'Pacific/Kiritimati', 'Pacific/Tarawa'],
    },
    {
      id: 'KM',
      name: 'Comoros',
      timezones: ['Africa/Nairobi'],
    },
    {
      id: 'KN',
      name: 'Saint Kitts and Nevis',
      timezones: ['America/Puerto_Rico'],
    },
    {
      id: 'KP',
      name: 'North Korea',
      timezones: ['Asia/Pyongyang'],
    },
    {
      id: 'KR',
      name: 'South Korea',
      timezones: ['Asia/Seoul'],
    },
    {
      id: 'KW',
      name: 'Kuwait',
      timezones: ['Asia/Riyadh'],
    },
    {
      id: 'KY',
      name: 'Cayman Islands',
      timezones: ['America/Panama'],
    },
    {
      id: 'KZ',
      name: 'Kazakhstan',
      timezones: [
        'Asia/Almaty',
        'Asia/Aqtau',
        'Asia/Aqtobe',
        'Asia/Atyrau',
        'Asia/Oral',
        'Asia/Qostanay',
        'Asia/Qyzylorda',
      ],
    },
    {
      id: 'LA',
      name: 'Laos',
      timezones: ['Asia/Bangkok'],
    },
    {
      id: 'LB',
      name: 'Lebanon',
      timezones: ['Asia/Beirut'],
    },
    {
      id: 'LC',
      name: 'Saint Lucia',
      timezones: ['America/Puerto_Rico'],
    },
    {
      id: 'LI',
      name: 'Liechtenstein',
      timezones: ['Europe/Zurich'],
    },
    {
      id: 'LK',
      name: 'Sri Lanka',
      timezones: ['Asia/Colombo'],
    },
    {
      id: 'LR',
      name: 'Liberia',
      timezones: ['Africa/Monrovia'],
    },
    {
      id: 'LS',
      name: 'Lesotho',
      timezones: ['Africa/Johannesburg'],
    },
    {
      id: 'LT',
      name: 'Lithuania',
      timezones: ['Europe/Vilnius'],
    },
    {
      id: 'LU',
      name: 'Luxembourg',
      timezones: ['Europe/Brussels'],
    },
    {
      id: 'LV',
      name: 'Latvia',
      timezones: ['Europe/Riga'],
    },
    {
      id: 'LY',
      name: 'Libya',
      timezones: ['Africa/Tripoli'],
    },
    {
      id: 'MA',
      name: 'Morocco',
      timezones: ['Africa/Casablanca'],
    },
    {
      id: 'MC',
      name: 'Monaco',
      timezones: ['Europe/Paris'],
    },
    {
      id: 'MD',
      name: 'Moldova',
      timezones: ['Europe/Chisinau'],
    },
    {
      id: 'ME',
      name: 'Montenegro',
      timezones: ['Europe/Belgrade'],
    },
    {
      id: 'MF',
      name: 'Saint Martin',
      timezones: ['America/Puerto_Rico'],
    },
    {
      id: 'MG',
      name: 'Madagascar',
      timezones: ['Africa/Nairobi'],
    },
    {
      id: 'MH',
      name: 'Marshall Islands',
      timezones: ['Pacific/Kwajalein', 'Pacific/Tarawa'],
    },
    {
      id: 'MK',
      name: 'North Macedonia',
      timezones: ['Europe/Belgrade'],
    },
    {
      id: 'ML',
      name: 'Mali',
      timezones: ['Africa/Abidjan'],
    },
    {
      id: 'MM',
      name: 'Myanmar',
      timezones: ['Asia/Yangon'],
    },
    {
      id: 'MN',
      name: 'Mongolia',
      timezones: ['Asia/Choibalsan', 'Asia/Hovd', 'Asia/Ulaanbaatar'],
    },
    {
      id: 'MO',
      name: 'Macao',
      timezones: ['Asia/Macau'],
    },
    {
      id: 'MP',
      name: 'Northern Mariana Islands',
      timezones: ['Pacific/Guam'],
    },
    {
      id: 'MQ',
      name: 'Martinique',
      timezones: ['America/Martinique'],
    },
    {
      id: 'MR',
      name: 'Mauritania',
      timezones: ['Africa/Abidjan'],
    },
    {
      id: 'MS',
      name: 'Montserrat',
      timezones: ['America/Puerto_Rico'],
    },
    {
      id: 'MT',
      name: 'Malta',
      timezones: ['Europe/Malta'],
    },
    {
      id: 'MU',
      name: 'Mauritius',
      timezones: ['Indian/Mauritius'],
    },
    {
      id: 'MV',
      name: 'Maldives',
      timezones: ['Indian/Maldives'],
    },
    {
      id: 'MW',
      name: 'Malawi',
      timezones: ['Africa/Maputo'],
    },
    {
      id: 'MX',
      name: 'Mexico',
      timezones: [
        'America/Bahia_Banderas',
        'America/Cancun',
        'America/Chihuahua',
        'America/Ciudad_Juarez',
        'America/Hermosillo',
        'America/Matamoros',
        'America/Mazatlan',
        'America/Merida',
        'America/Mexico_City',
        'America/Monterrey',
        'America/Ojinaga',
        'America/Tijuana',
      ],
    },
    {
      id: 'MY',
      name: 'Malaysia',
      timezones: ['Asia/Kuching', 'Asia/Singapore'],
    },
    {
      id: 'MZ',
      name: 'Mozambique',
      timezones: ['Africa/Maputo'],
    },
    {
      id: 'NA',
      name: 'Namibia',
      timezones: ['Africa/Windhoek'],
    },
    {
      id: 'NC',
      name: 'New Caledonia',
      timezones: ['Pacific/Noumea'],
    },
    {
      id: 'NE',
      name: 'Niger',
      timezones: ['Africa/Lagos'],
    },
    {
      id: 'NF',
      name: 'Norfolk Island',
      timezones: ['Pacific/Norfolk'],
    },
    {
      id: 'NG',
      name: 'Nigeria',
      timezones: ['Africa/Lagos'],
    },
    {
      id: 'NI',
      name: 'Nicaragua',
      timezones: ['America/Managua'],
    },
    {
      id: 'NL',
      name: 'Netherlands',
      timezones: ['Europe/Brussels'],
    },
    {
      id: 'NO',
      name: 'Norway',
      timezones: ['Europe/Berlin'],
    },
    {
      id: 'NP',
      name: 'Nepal',
      timezones: ['Asia/Kathmandu'],
    },
    {
      id: 'NR',
      name: 'Nauru',
      timezones: ['Pacific/Nauru'],
    },
    {
      id: 'NU',
      name: 'Niue',
      timezones: ['Pacific/Niue'],
    },
    {
      id: 'NZ',
      name: 'New Zealand',
      timezones: ['Pacific/Auckland', 'Pacific/Chatham'],
    },
    {
      id: 'OM',
      name: 'Oman',
      timezones: ['Asia/Dubai'],
    },
    {
      id: 'PA',
      name: 'Panama',
      timezones: ['America/Panama'],
    },
    {
      id: 'PE',
      name: 'Peru',
      timezones: ['America/Lima'],
    },
    {
      id: 'PF',
      name: 'French Polynesia',
      timezones: ['Pacific/Gambier', 'Pacific/Marquesas', 'Pacific/Tahiti'],
    },
    {
      id: 'PG',
      name: 'Papua New Guinea',
      timezones: ['Pacific/Bougainville', 'Pacific/Port_Moresby'],
    },
    {
      id: 'PH',
      name: 'Philippines',
      timezones: ['Asia/Manila'],
    },
    {
      id: 'PK',
      name: 'Pakistan',
      timezones: ['Asia/Karachi'],
    },
    {
      id: 'PL',
      name: 'Poland',
      timezones: ['Europe/Warsaw'],
    },
    {
      id: 'PM',
      name: 'Saint Pierre and Miquelon',
      timezones: ['America/Miquelon'],
    },
    {
      id: 'PN',
      name: 'Pitcairn',
      timezones: ['Pacific/Pitcairn'],
    },
    {
      id: 'PR',
      name: 'Puerto Rico',
      timezones: ['America/Puerto_Rico'],
    },
    {
      id: 'PS',
      name: 'Palestine',
      timezones: ['Asia/Gaza', 'Asia/Hebron'],
    },
    {
      id: 'PT',
      name: 'Portugal',
      timezones: ['Atlantic/Azores', 'Atlantic/Madeira', 'Europe/Lisbon'],
    },
    {
      id: 'PW',
      name: 'Palau',
      timezones: ['Pacific/Palau'],
    },
    {
      id: 'PY',
      name: 'Paraguay',
      timezones: ['America/Asuncion'],
    },
    {
      id: 'QA',
      name: 'Qatar',
      timezones: ['Asia/Qatar'],
    },
    {
      id: 'RE',
      name: 'Réunion',
      timezones: ['Asia/Dubai'],
    },
    {
      id: 'RO',
      name: 'Romania',
      timezones: ['Europe/Bucharest'],
    },
    {
      id: 'RS',
      name: 'Serbia',
      timezones: ['Europe/Belgrade'],
    },
    {
      id: 'RU',
      name: 'Russia',
      timezones: [
        'Asia/Anadyr',
        'Asia/Barnaul',
        'Asia/Chita',
        'Asia/Irkutsk',
        'Asia/Kamchatka',
        'Asia/Khandyga',
        'Asia/Krasnoyarsk',
        'Asia/Magadan',
        'Asia/Novokuznetsk',
        'Asia/Novosibirsk',
        'Asia/Omsk',
        'Asia/Sakhalin',
        'Asia/Srednekolymsk',
        'Asia/Tomsk',
        'Asia/Ust-Nera',
        'Asia/Vladivostok',
        'Asia/Yakutsk',
        'Asia/Yekaterinburg',
        'Europe/Astrakhan',
        'Europe/Kaliningrad',
        'Europe/Kirov',
        'Europe/Moscow',
        'Europe/Samara',
        'Europe/Saratov',
        'Europe/Simferopol',
        'Europe/Ulyanovsk',
        'Europe/Volgograd',
      ],
    },
    {
      id: 'RW',
      name: 'Rwanda',
      timezones: ['Africa/Maputo'],
    },
    {
      id: 'SA',
      name: 'Saudi Arabia',
      timezones: ['Asia/Riyadh'],
    },
    {
      id: 'SB',
      name: 'Solomon Islands',
      timezones: ['Pacific/Guadalcanal'],
    },
    {
      id: 'SC',
      name: 'Seychelles',
      timezones: ['Asia/Dubai'],
    },
    {
      id: 'SD',
      name: 'Sudan',
      timezones: ['Africa/Khartoum'],
    },
    {
      id: 'SE',
      name: 'Sweden',
      timezones: ['Europe/Berlin'],
    },
    {
      id: 'SG',
      name: 'Singapore',
      timezones: ['Asia/Singapore'],
    },
    {
      id: 'SH',
      name: 'Saint Helena, Ascension and Tristan da Cunha',
      timezones: ['Africa/Abidjan'],
    },
    {
      id: 'SI',
      name: 'Slovenia',
      timezones: ['Europe/Belgrade'],
    },
    {
      id: 'SJ',
      name: 'Svalbard and Jan Mayen',
      timezones: ['Europe/Berlin'],
    },
    {
      id: 'SK',
      name: 'Slovakia',
      timezones: ['Europe/Prague'],
    },
    {
      id: 'SL',
      name: 'Sierra Leone',
      timezones: ['Africa/Abidjan'],
    },
    {
      id: 'SM',
      name: 'San Marino',
      timezones: ['Europe/Rome'],
    },
    {
      id: 'SN',
      name: 'Senegal',
      timezones: ['Africa/Abidjan'],
    },
    {
      id: 'SO',
      name: 'Somalia',
      timezones: ['Africa/Nairobi'],
    },
    {
      id: 'SR',
      name: 'Suriname',
      timezones: ['America/Paramaribo'],
    },
    {
      id: 'SS',
      name: 'South Sudan',
      timezones: ['Africa/Juba'],
    },
    {
      id: 'ST',
      name: 'Sao Tome and Principe',
      timezones: ['Africa/Sao_Tome'],
    },
    {
      id: 'SV',
      name: 'El Salvador',
      timezones: ['America/El_Salvador'],
    },
    {
      id: 'SX',
      name: 'Sint Maarten',
      timezones: ['America/Puerto_Rico'],
    },
    {
      id: 'SY',
      name: 'Syria',
      timezones: ['Asia/Damascus'],
    },
    {
      id: 'SZ',
      name: 'Eswatini',
      timezones: ['Africa/Johannesburg'],
    },
    {
      id: 'TC',
      name: 'Turks and Caicos Islands',
      timezones: ['America/Grand_Turk'],
    },
    {
      id: 'TD',
      name: 'Chad',
      timezones: ['Africa/Ndjamena'],
    },
    {
      id: 'TF',
      name: 'French Southern Territories',
      timezones: ['Asia/Dubai', 'Indian/Maldives'],
    },
    {
      id: 'TG',
      name: 'Togo',
      timezones: ['Africa/Abidjan'],
    },
    {
      id: 'TH',
      name: 'Thailand',
      timezones: ['Asia/Bangkok'],
    },
    {
      id: 'TJ',
      name: 'Tajikistan',
      timezones: ['Asia/Dushanbe'],
    },
    {
      id: 'TK',
      name: 'Tokelau',
      timezones: ['Pacific/Fakaofo'],
    },
    {
      id: 'TL',
      name: 'Timor-Leste',
      timezones: ['Asia/Dili'],
    },
    {
      id: 'TM',
      name: 'Turkmenistan',
      timezones: ['Asia/Ashgabat'],
    },
    {
      id: 'TN',
      name: 'Tunisia',
      timezones: ['Africa/Tunis'],
    },
    {
      id: 'TO',
      name: 'Tonga',
      timezones: ['Pacific/Tongatapu'],
    },
    {
      id: 'TR',
      name: 'Türkiye',
      timezones: ['Europe/Istanbul'],
    },
    {
      id: 'TT',
      name: 'Trinidad and Tobago',
      timezones: ['America/Puerto_Rico'],
    },
    {
      id: 'TV',
      name: 'Tuvalu',
      timezones: ['Pacific/Tarawa'],
    },
    {
      id: 'TW',
      name: 'Taiwan',
      timezones: ['Asia/Taipei'],
    },
    {
      id: 'TZ',
      name: 'Tanzania',
      timezones: ['Africa/Nairobi'],
    },
    {
      id: 'UA',
      name: 'Ukraine',
      timezones: ['Europe/Kyiv', 'Europe/Simferopol'],
    },
    {
      id: 'UG',
      name: 'Uganda',
      timezones: ['Africa/Nairobi'],
    },
    {
      id: 'UM',
      name: 'United States Minor Outlying Islands',
      timezones: ['Pacific/Pago_Pago', 'Pacific/Tarawa'],
    },
    {
      id: 'US',
      name: 'United States of America',
      timezones: [
        'America/Adak',
        'America/Anchorage',
        'America/Boise',
        'America/Chicago',
        'America/Denver',
        'America/Detroit',
        'America/Indiana/Indianapolis',
        'America/Indiana/Knox',
        'America/Indiana/Marengo',
        'America/Indiana/Petersburg',
        'America/Indiana/Tell_City',
        'America/Indiana/Vevay',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Juneau',
        'America/Kentucky/Louisville',
        'America/Kentucky/Monticello',
        'America/Los_Angeles',
        'America/Menominee',
        'America/Metlakatla',
        'America/New_York',
        'America/Nome',
        'America/North_Dakota/Beulah',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/Phoenix',
        'America/Sitka',
        'America/Yakutat',
        'Pacific/Honolulu',
      ],
    },
    {
      id: 'UY',
      name: 'Uruguay',
      timezones: ['America/Montevideo'],
    },
    {
      id: 'UZ',
      name: 'Uzbekistan',
      timezones: ['Asia/Samarkand', 'Asia/Tashkent'],
    },
    {
      id: 'VA',
      name: 'Holy See',
      timezones: ['Europe/Rome'],
    },
    {
      id: 'VC',
      name: 'Saint Vincent and the Grenadines',
      timezones: ['America/Puerto_Rico'],
    },
    {
      id: 'VE',
      name: 'Venezuela',
      timezones: ['America/Caracas'],
    },
    {
      id: 'VG',
      name: 'Virgin Islands (UK)',
      timezones: ['America/Puerto_Rico'],
    },
    {
      id: 'VI',
      name: 'Virgin Islands (US)',
      timezones: ['America/Puerto_Rico'],
    },
    {
      id: 'VN',
      name: 'Vietnam',
      timezones: ['Asia/Bangkok', 'Asia/Ho_Chi_Minh'],
    },
    {
      id: 'VU',
      name: 'Vanuatu',
      timezones: ['Pacific/Efate'],
    },
    {
      id: 'WF',
      name: 'Wallis and Futuna',
      timezones: ['Pacific/Tarawa'],
    },
    {
      id: 'WS',
      name: 'Samoa',
      timezones: ['Pacific/Apia'],
    },
    {
      id: 'YE',
      name: 'Yemen',
      timezones: ['Asia/Riyadh'],
    },
    {
      id: 'YT',
      name: 'Mayotte',
      timezones: ['Africa/Nairobi'],
    },
    {
      id: 'ZA',
      name: 'South Africa',
      timezones: ['Africa/Johannesburg'],
    },
    {
      id: 'ZM',
      name: 'Zambia',
      timezones: ['Africa/Maputo'],
    },
    {
      id: 'ZW',
      name: 'Zimbabwe',
      timezones: ['Africa/Maputo'],
    },
  ];

  currency = {
    DOLLER: "$",
    INR: "₹",
    AED: "د.إ",
    USD: "$",
    EUR: "€",
    GBP: "£",
    JPY: "¥",
    CNY: "元",
    AUD: "A$",
    CAD: "C$",
    CHF: "Fr",
    MXN: "Mex$",
    BRL: "R$",
    RUB: "₽",
    KRW: "₩",
    ZAR: "R",
    NZD: "NZ$",
    SGD: "S$",
    HKD: "HK$",
    SEK: "kr",
    NOK: "kr",
    TRY: "₺",
    SAR: "﷼",   // Saudi Riyal
    QAR: "﷼",   // Qatari Riyal (same symbol as SAR)
    EGP: "£",   // Egyptian Pound
    IDR: "Rp",  // Indonesian Rupiah
  }
  categoryList: any = [
    { value: 'AUTO', label: 'Automotive' },
    { value: 'BEAUTY', label: 'Beauty, Spa and Salon' },
    { value: 'APPAREL', label: 'Clothing and Apparel' },
    { value: 'EDU', label: 'Education' },
    { value: 'ENTERTAIN', label: 'Entertainment' },
    { value: 'EVENT_PLAN', label: 'Event Planning and Service' },
    { value: 'FINANCE', label: 'Finance and Banking' },
    { value: 'GROCERY', label: 'Food and Grocery' },
    { value: 'GOVT', label: 'Public Service' },
    { value: 'HOTEL', label: 'Hotel and Lodging' },
    { value: 'HEALTH', label: 'Medical and Health' },
    { value: 'NONPROFIT', label: 'Non-profit' },
    { value: 'PROF_SERVICES', label: 'Professional Services' },
    { value: 'RETAIL', label: 'Shopping and Retail' },
    { value: 'TRAVEL', label: 'Travel and Transportation' },
    { value: 'RESTAURANT', label: 'Restaurant' },
    { value: 'OTHER', label: 'Other' },
  ];

  errorObject: any = {
    "0": "We were unable to authenticate the app user.",
    "3": "Capability or permissions issue.",
    "10": "Permission is either not granted or has been removed.",
    "190": "Your access token has expired.",
    "200-299": "Permission is either not granted or has been removed.",
    "4": "The app has reached its API call rate limit.",
    "80007": "The WhatsApp Business Account has reached its rate limit.",
    "130429": "Cloud API message throughput has been reached.",
    "131048": "Message failed to send because there are restrictions on how many messages can be sent from this phone number. This may be because too many previous messages were blocked or flagged as spam.",
    "131056": "Too many messages sent from the sender phone number to the same recipient phone number in a short period of time.",
    "133016": "Registration or Deregistration failed because there were too many attempts for this phone number in a short period of time",
    "368": "The WhatsApp Business Account associated with the app has been restricted or disabled for violating a platform policy.",
    "131031": "The WhatsApp Business Account associated with the app has been restricted or disabled for violating a platform policy, or we were unable to verify data included in the request against data set on the WhatsApp Business Account (e.g, the two-step pin included in the request is incorrect).",
    "1": "Invalid request or possible server error.",
    "2": "Temporary due to downtime or due to being overloaded.",
    "33": "The business phone number has been deleted.",
    "100": "The request included one or more unsupported or misspelled parameters.",
    "130472": "Message was not sent as part of an experiment.",
    "131000": "Message failed to send due to an unknown error. When setting a business public key, it either failed to calculate the signature, call the GraphQL endpoint, or the GraphQL endpoint returned an error.",
    "131005": "Permission is either not granted or has been removed.",
    "131008": "The request is missing a required parameter.",
    "131009": "One or more parameter values are invalid.",
    "131016": "A service is temporarily unavailable.",
    "131021": "Sender and recipient phone number is the same.",
    "131026": "Unable to deliver message. Reasons can include: The recipient phone number is not a WhatsApp phone number. Recipient has not accepted our new Terms of Service and Privacy Policy",
    "131042": "There was an error related to your payment method.",
    "131045": "Message failed to send due to a phone number registration error.",
    "131047": "More than 24 hours have passed since the recipient last replied to the sender number.",
    "131051": "Unsupported message type.",
    "131052": "Unable to download the media sent by the user.",
    "131053": "Unable to upload the media used in the message.",
    "131057": "Buiness Account is in maintenance mode",
    "132000": "The number of variable parameter values included in the request did not match the number of variable parameters defined in the template.",
    "132001": "The template does not exist in the specified language or the template has not been approved.",
    "132005": "Translated text is too long.",
    "132007": "Template content violates a WhatsApp policy.",
    "132012": "Variable parameter values formatted incorrectly.",
    "132015": "Template is paused due to low quality so it cannot be sent in a template message.",
    "132016": "Template has been paused too many times due to low quality and is now permanently disabled.",
    "132068": "Flow is in blocked state.",
    "132069": "Flow is in throttled state and 10 messages using this flow were already sent in the last hour.",
    "133000": "A previous deregistration attempt failed.",
    "133004": "Server is temporarily unavailable.",
    "133005": "Two-step verification PIN incorrect.",
    "133006": "Phone number needs to be verified before registering.",
    "133008": "Too many two-step verification PIN guesses for this phone number.",
    "133009": "Two-step verification PIN was entered too quickly.",
    "133010": "Phone number not registered on the WhatsApp Business Platform.",
    "133015": "The phone number you are attempting to register was recently deleted, and deletion has not yet completed.",
    "135000": "Message failed to send because of an unknown error with your request parameters."
  }

  paymentGatewayIds = {
    PAYPAL: "63a42e1b10b74a4bff79fb3d",
    STRIPE: "63a429c110b74a4bff79fb37"
  }

  paymentplan2: any;

  packageName: any = ''
  currency_code: any = [
    {
      "country": "India",
      "currency_code": "INR"
    },
    {
      "country": "United Arab Emirates Dirham",
      "currency_code": "AED"
    },
    {
      "country": "Saudi Riyal",
      "currency_code": "SAR"
    },
    {
      "country": "Qatari Riyal",
      "currency_code": "QAR"
    },
    {
      "country": "Egyptian Pound",
      "currency_code": "EGP"
    },
    {
      "country": "Indonesian Rupiah",
      "currency_code": "IDR"
    },
    {
      "country": "Hong Kong",
      "currency_code": "HKD"
    },
  ]
  LanguageCode: any = [
    {
      name: 'Afrikaans',
      code: 'af',
    },
    {
      name: 'Albanian',
      code: 'sq',
    },
    {
      name: 'Arabic',
      code: 'ar',
    },
    {
      name: 'Azerbaijani',
      code: 'az',
    },
    {
      name: 'Bengali',
      code: 'bn',
    },
    {
      name: 'Bulgarian',
      code: 'bg',
    },
    {
      name: 'Catalan',
      code: 'ca',
    },
    {
      name: 'Chinese (CHN)',
      code: 'zh_CN',
    },
    {
      name: 'Chinese (HKG)',
      code: 'zh_HK',
    },
    {
      name: 'Chinese (TAI)',
      code: 'zh_TW',
    },
    {
      name: 'Croatian',
      code: 'hr',
    },
    {
      name: 'Czech',
      code: 'cs',
    },
    {
      name: 'Danish',
      code: 'da',
    },
    {
      name: 'Dutch',
      code: 'nl',
    },
    {
      name: 'English',
      code: 'en',
    },
    {
      name: 'English (UK)',
      code: 'en_GB',
    },
    {
      name: 'English (US)',
      code: 'en_US',
    },
    {
      name: 'Estonian',
      code: 'et',
    },
    {
      name: 'Filipino',
      code: 'fil',
    },
    {
      name: 'Finnish',
      code: 'fi',
    },
    {
      name: 'French',
      code: 'fr',
    },
    {
      name: 'German',
      code: 'de',
    },
    {
      name: 'Greek',
      code: 'el',
    },
    {
      name: 'Gujarati',
      code: 'gu',
    },
    {
      name: 'Hausa',
      code: 'ha',
    },
    {
      name: 'Hebrew',
      code: 'he',
    },
    {
      name: 'Hindi',
      code: 'hi',
    },
    {
      name: 'Hungarian',
      code: 'hu',
    },
    {
      name: 'Indonesian',
      code: 'id',
    },
    {
      name: 'Irish',
      code: 'ga',
    },
    {
      name: 'Italian',
      code: 'it',
    },
    {
      name: 'Japanese',
      code: 'ja',
    },
    {
      name: 'Kannada',
      code: 'kn',
    },
    {
      name: 'Kazakh',
      code: 'kk',
    },
    {
      name: 'Korean',
      code: 'ko',
    },
    {
      name: 'Lao',
      code: 'lo',
    },
    {
      name: 'Latvian',
      code: 'lv',
    },
    {
      name: 'Lithuanian',
      code: 'lt',
    },
    {
      name: 'Macedonian',
      code: 'mk',
    },
    {
      name: 'Malay',
      code: 'ms',
    },
    {
      name: 'Malayalam',
      code: 'ml',
    },
    {
      name: 'Marathi',
      code: 'mr',
    },
    {
      name: 'Norwegian',
      code: 'nb',
    },
    {
      name: 'Persian',
      code: 'fa',
    },
    {
      name: 'Polish',
      code: 'pl',
    },
    {
      name: 'Portuguese (BR)',
      code: 'pt_BR',
    },
    {
      name: 'Portuguese (POR)',
      code: 'pt_PT',
    },
    {
      name: 'Punjabi',
      code: 'pa',
    },
    {
      name: 'Romanian',
      code: 'ro',
    },
    {
      name: 'Russian',
      code: 'ru',
    },
    {
      name: 'Serbian',
      code: 'sr',
    },
    {
      name: 'Slovak',
      code: 'sk',
    },
    {
      name: 'Slovenian',
      code: 'sl',
    },
    {
      name: 'Spanish',
      code: 'es',
    },
    {
      name: 'Spanish (ARG)',
      code: 'es_AR',
    },
    {
      name: 'Spanish (SPA)',
      code: 'es_ES',
    },
    {
      name: 'Spanish (MEX)',
      code: 'es_MX',
    },
    {
      name: 'Swahili',
      code: 'sw',
    },
    {
      name: 'Swedish',
      code: 'sv',
    },
    {
      name: 'Tamil',
      code: 'ta',
    },
    {
      name: 'Telugu',
      code: 'te',
    },
    {
      name: 'Thai',
      code: 'th',
    },
    {
      name: 'Turkish',
      code: 'tr',
    },
    {
      name: 'Ukrainian',
      code: 'uk',
    },
    {
      name: 'Urdu',
      code: 'ur',
    },
    {
      name: 'Uzbek',
      code: 'uz',
    },
    {
      name: 'Vietnamese',
      code: 'vi',
    },
    {
      name: 'Zulu',
      code: 'zu',
    },
  ];
  // {
  //     "country": "US Dollar",
  //     "currency_code": "USD"
  // },
  // currency_code: any = [
  //     {
  //         "country": "India",
  //         "currency_code": "INR"
  //     },
  //     {
  //         "country": "American Samoa",
  //         "currency_code": "USD"
  //     },



  //     {
  //         "country": "Afghanistan",
  //         "currency_code": "AFN"
  //     },
  //     {
  //         "country": "Albania",
  //         "currency_code": "ALL"
  //     },
  //     {
  //         "country": "Algeria",
  //         "currency_code": "DZD"
  //     },
  //     {
  //         "country": "Andorra",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Angola",
  //         "currency_code": "AOA"
  //     },
  //     {
  //         "country": "Anguilla",
  //         "currency_code": "XCD"
  //     },
  //     {
  //         "country": "Antarctica",
  //         "currency_code": "XCD"
  //     },
  //     {
  //         "country": "Antigua and Barbuda",
  //         "currency_code": "XCD"
  //     },
  //     {
  //         "country": "Argentina",
  //         "currency_code": "ARS"
  //     },
  //     {
  //         "country": "Armenia",
  //         "currency_code": "AMD"
  //     },
  //     {
  //         "country": "Aruba",
  //         "currency_code": "AWG"
  //     },
  //     {
  //         "country": "Australia",
  //         "currency_code": "AUD"
  //     },
  //     {
  //         "country": "Austria",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Azerbaijan",
  //         "currency_code": "AZN"
  //     },
  //     {
  //         "country": "Bahamas",
  //         "currency_code": "BSD"
  //     },
  //     {
  //         "country": "Bahrain",
  //         "currency_code": "BHD"
  //     },
  //     {
  //         "country": "Bangladesh",
  //         "currency_code": "BDT"
  //     },
  //     {
  //         "country": "Barbados",
  //         "currency_code": "BBD"
  //     },
  //     {
  //         "country": "Belarus",
  //         "currency_code": "BYR"
  //     },
  //     {
  //         "country": "Belgium",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Belize",
  //         "currency_code": "BZD"
  //     },
  //     {
  //         "country": "Benin",
  //         "currency_code": "XOF"
  //     },
  //     {
  //         "country": "Bermuda",
  //         "currency_code": "BMD"
  //     },
  //     {
  //         "country": "Bhutan",
  //         "currency_code": "BTN"
  //     },
  //     {
  //         "country": "Bolivia",
  //         "currency_code": "BOB"
  //     },
  //     {
  //         "country": "Bosnia and Herzegovina",
  //         "currency_code": "BAM"
  //     },
  //     {
  //         "country": "Botswana",
  //         "currency_code": "BWP"
  //     },
  //     {
  //         "country": "Bouvet Island",
  //         "currency_code": "NOK"
  //     },
  //     {
  //         "country": "Brazil",
  //         "currency_code": "BRL"
  //     },
  //     {
  //         "country": "British Indian Ocean Territory",
  //         "currency_code": "USD"
  //     },
  //     {
  //         "country": "Brunei",
  //         "currency_code": "BND"
  //     },
  //     {
  //         "country": "Bulgaria",
  //         "currency_code": "BGN"
  //     },
  //     {
  //         "country": "Burkina Faso",
  //         "currency_code": "XOF"
  //     },
  //     {
  //         "country": "Burundi",
  //         "currency_code": "BIF"
  //     },
  //     {
  //         "country": "Cambodia",
  //         "currency_code": "KHR"
  //     },
  //     {
  //         "country": "Cameroon",
  //         "currency_code": "XAF"
  //     },
  //     {
  //         "country": "Canada",
  //         "currency_code": "CAD"
  //     },
  //     {
  //         "country": "Cape Verde",
  //         "currency_code": "CVE"
  //     },
  //     {
  //         "country": "Cayman Islands",
  //         "currency_code": "KYD"
  //     },
  //     {
  //         "country": "Central African Republic",
  //         "currency_code": "XAF"
  //     },
  //     {
  //         "country": "Chad",
  //         "currency_code": "XAF"
  //     },
  //     {
  //         "country": "Chile",
  //         "currency_code": "CLP"
  //     },
  //     {
  //         "country": "China",
  //         "currency_code": "CNY"
  //     },
  //     {
  //         "country": "Christmas Island",
  //         "currency_code": "AUD"
  //     },
  //     {
  //         "country": "Cocos (Keeling) Islands",
  //         "currency_code": "AUD"
  //     },
  //     {
  //         "country": "Colombia",
  //         "currency_code": "COP"
  //     },
  //     {
  //         "country": "Comoros",
  //         "currency_code": "KMF"
  //     },
  //     {
  //         "country": "Congo",
  //         "currency_code": "XAF"
  //     },
  //     {
  //         "country": "Cook Islands",
  //         "currency_code": "NZD"
  //     },
  //     {
  //         "country": "Costa Rica",
  //         "currency_code": "CRC"
  //     },
  //     {
  //         "country": "Croatia",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Cuba",
  //         "currency_code": "CUP"
  //     },
  //     {
  //         "country": "Cyprus",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Czech Republic",
  //         "currency_code": "CZK"
  //     },
  //     {
  //         "country": "Denmark",
  //         "currency_code": "DKK"
  //     },
  //     {
  //         "country": "Djibouti",
  //         "currency_code": "DJF"
  //     },
  //     {
  //         "country": "Dominica",
  //         "currency_code": "XCD"
  //     },
  //     {
  //         "country": "Dominican Republic",
  //         "currency_code": "DOP"
  //     },
  //     {
  //         "country": "East Timor",
  //         "currency_code": "USD"
  //     },
  //     {
  //         "country": "Ecuador",
  //         "currency_code": "ECS"
  //     },
  //     {
  //         "country": "Egypt",
  //         "currency_code": "EGP"
  //     },
  //     {
  //         "country": "El Salvador",
  //         "currency_code": "SVC"
  //     },
  //     {
  //         "country": "England",
  //         "currency_code": "GBP"
  //     },
  //     {
  //         "country": "Equatorial Guinea",
  //         "currency_code": "XAF"
  //     },
  //     {
  //         "country": "Eritrea",
  //         "currency_code": "ERN"
  //     },
  //     {
  //         "country": "Estonia",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Ethiopia",
  //         "currency_code": "ETB"
  //     },
  //     {
  //         "country": "Falkland Islands",
  //         "currency_code": "FKP"
  //     },
  //     {
  //         "country": "Faroe Islands",
  //         "currency_code": "DKK"
  //     },
  //     {
  //         "country": "Fiji Islands",
  //         "currency_code": "FJD"
  //     },
  //     {
  //         "country": "Finland",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "France",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "French Guiana",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "French Polynesia",
  //         "currency_code": "XPF"
  //     },
  //     {
  //         "country": "French Southern territories",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Gabon",
  //         "currency_code": "XAF"
  //     },
  //     {
  //         "country": "Gambia",
  //         "currency_code": "GMD"
  //     },
  //     {
  //         "country": "Georgia",
  //         "currency_code": "GEL"
  //     },
  //     {
  //         "country": "Germany",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Ghana",
  //         "currency_code": "GHS"
  //     },
  //     {
  //         "country": "Gibraltar",
  //         "currency_code": "GIP"
  //     },
  //     {
  //         "country": "Greece",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Greenland",
  //         "currency_code": "DKK"
  //     },
  //     {
  //         "country": "Grenada",
  //         "currency_code": "XCD"
  //     },
  //     {
  //         "country": "Guadeloupe",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Guam",
  //         "currency_code": "USD"
  //     },
  //     {
  //         "country": "Guatemala",
  //         "currency_code": "QTQ"
  //     },
  //     {
  //         "country": "Guinea",
  //         "currency_code": "GNF"
  //     },
  //     {
  //         "country": "Guinea-Bissau",
  //         "currency_code": "CFA"
  //     },
  //     {
  //         "country": "Guyana",
  //         "currency_code": "GYD"
  //     },
  //     {
  //         "country": "Haiti",
  //         "currency_code": "HTG"
  //     },
  //     {
  //         "country": "Heard Island and McDonald Islands",
  //         "currency_code": "AUD"
  //     },
  //     {
  //         "country": "Holy See (Vatican City State)",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Honduras",
  //         "currency_code": "HNL"
  //     },
  //     {
  //         "country": "Hong Kong",
  //         "currency_code": "HKD"
  //     },
  //     {
  //         "country": "Hungary",
  //         "currency_code": "HUF"
  //     },
  //     {
  //         "country": "Iceland",
  //         "currency_code": "ISK"
  //     },
  //     {
  //         "country": "Indonesia",
  //         "currency_code": "IDR"
  //     },
  //     {
  //         "country": "Iran",
  //         "currency_code": "IRR"
  //     },
  //     {
  //         "country": "Iraq",
  //         "currency_code": "IQD"
  //     },
  //     {
  //         "country": "Ireland",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Israel",
  //         "currency_code": "ILS"
  //     },
  //     {
  //         "country": "Italy",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Ivory Coast",
  //         "currency_code": "XOF"
  //     },
  //     {
  //         "country": "Jamaica",
  //         "currency_code": "JMD"
  //     },
  //     {
  //         "country": "Japan",
  //         "currency_code": "JPY"
  //     },
  //     {
  //         "country": "Jordan",
  //         "currency_code": "JOD"
  //     },
  //     {
  //         "country": "Kazakhstan",
  //         "currency_code": "KZT"
  //     },
  //     {
  //         "country": "Kenya",
  //         "currency_code": "KES"
  //     },
  //     {
  //         "country": "Kiribati",
  //         "currency_code": "AUD"
  //     },
  //     {
  //         "country": "Kuwait",
  //         "currency_code": "KWD"
  //     },
  //     {
  //         "country": "Kyrgyzstan",
  //         "currency_code": "KGS"
  //     },
  //     {
  //         "country": "Laos",
  //         "currency_code": "LAK"
  //     },
  //     {
  //         "country": "Latvia",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Lebanon",
  //         "currency_code": "LBP"
  //     },
  //     {
  //         "country": "Lesotho",
  //         "currency_code": "LSL"
  //     },
  //     {
  //         "country": "Liberia",
  //         "currency_code": "LRD"
  //     },
  //     {
  //         "country": "Libyan Arab Jamahiriya",
  //         "currency_code": "LYD"
  //     },
  //     {
  //         "country": "Liechtenstein",
  //         "currency_code": "CHF"
  //     },
  //     {
  //         "country": "Lithuania",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Luxembourg",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Macau",
  //         "currency_code": "MOP"
  //     },
  //     {
  //         "country": "North Macedonia",
  //         "currency_code": "MKD"
  //     },
  //     {
  //         "country": "Madagascar",
  //         "currency_code": "MGF"
  //     },
  //     {
  //         "country": "Malawi",
  //         "currency_code": "MWK"
  //     },
  //     {
  //         "country": "Malaysia",
  //         "currency_code": "MYR"
  //     },
  //     {
  //         "country": "Maldives",
  //         "currency_code": "MVR"
  //     },
  //     {
  //         "country": "Mali",
  //         "currency_code": "XOF"
  //     },
  //     {
  //         "country": "Malta",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Marshall Islands",
  //         "currency_code": "USD"
  //     },
  //     {
  //         "country": "Martinique",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Mauritania",
  //         "currency_code": "MRO"
  //     },
  //     {
  //         "country": "Mauritius",
  //         "currency_code": "MUR"
  //     },
  //     {
  //         "country": "Mayotte",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Mexico",
  //         "currency_code": "MXN"
  //     },
  //     {
  //         "country": "Micronesia, Federated States of",
  //         "currency_code": "USD"
  //     },
  //     {
  //         "country": "Moldova",
  //         "currency_code": "MDL"
  //     },
  //     {
  //         "country": "Monaco",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Mongolia",
  //         "currency_code": "MNT"
  //     },
  //     {
  //         "country": "Montserrat",
  //         "currency_code": "XCD"
  //     },
  //     {
  //         "country": "Morocco",
  //         "currency_code": "MAD"
  //     },
  //     {
  //         "country": "Mozambique",
  //         "currency_code": "MZN"
  //     },
  //     {
  //         "country": "Myanmar",
  //         "currency_code": "MMR"
  //     },
  //     {
  //         "country": "Namibia",
  //         "currency_code": "NAD"
  //     },
  //     {
  //         "country": "Nauru",
  //         "currency_code": "AUD"
  //     },
  //     {
  //         "country": "Nepal",
  //         "currency_code": "NPR"
  //     },
  //     {
  //         "country": "Netherlands",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Netherlands Antilles",
  //         "currency_code": "ANG"
  //     },
  //     {
  //         "country": "New Caledonia",
  //         "currency_code": "XPF"
  //     },
  //     {
  //         "country": "New Zealand",
  //         "currency_code": "NZD"
  //     },
  //     {
  //         "country": "Nicaragua",
  //         "currency_code": "NIO"
  //     },
  //     {
  //         "country": "Niger",
  //         "currency_code": "XOF"
  //     },
  //     {
  //         "country": "Nigeria",
  //         "currency_code": "NGN"
  //     },
  //     {
  //         "country": "Niue",
  //         "currency_code": "NZD"
  //     },
  //     {
  //         "country": "Norfolk Island",
  //         "currency_code": "AUD"
  //     },
  //     {
  //         "country": "North Korea",
  //         "currency_code": "KPW"
  //     },
  //     {
  //         "country": "Northern Ireland",
  //         "currency_code": "GBP"
  //     },
  //     {
  //         "country": "Northern Mariana Islands",
  //         "currency_code": "USD"
  //     },
  //     {
  //         "country": "Norway",
  //         "currency_code": "NOK"
  //     },
  //     {
  //         "country": "Oman",
  //         "currency_code": "OMR"
  //     },
  //     {
  //         "country": "Pakistan",
  //         "currency_code": "PKR"
  //     },
  //     {
  //         "country": "Palau",
  //         "currency_code": "USD"
  //     },
  //     {
  //         "country": "Palestine",
  //         "currency_code": null
  //     },
  //     {
  //         "country": "Panama",
  //         "currency_code": "PAB"
  //     },
  //     {
  //         "country": "Papua New Guinea",
  //         "currency_code": "PGK"
  //     },
  //     {
  //         "country": "Paraguay",
  //         "currency_code": "PYG"
  //     },
  //     {
  //         "country": "Peru",
  //         "currency_code": "PEN"
  //     },
  //     {
  //         "country": "Philippines",
  //         "currency_code": "PHP"
  //     },
  //     {
  //         "country": "Pitcairn Islands",
  //         "currency_code": "NZD"
  //     },
  //     {
  //         "country": "Poland",
  //         "currency_code": "PLN"
  //     },
  //     {
  //         "country": "Portugal",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Puerto Rico",
  //         "currency_code": "USD"
  //     },
  //     {
  //         "country": "Qatar",
  //         "currency_code": "QAR"
  //     },
  //     {
  //         "country": "Reunion",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Romania",
  //         "currency_code": "RON"
  //     },
  //     {
  //         "country": "Russia",
  //         "currency_code": "RUB"
  //     },
  //     {
  //         "country": "Rwanda",
  //         "currency_code": "RWF"
  //     },
  //     {
  //         "country": "Saint Helena",
  //         "currency_code": "SHP"
  //     },
  //     {
  //         "country": "Saint Kitts and Nevis",
  //         "currency_code": "XCD"
  //     },
  //     {
  //         "country": "Saint Lucia",
  //         "currency_code": "XCD"
  //     },
  //     {
  //         "country": "Saint Pierre and Miquelon",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Saint Vincent and the Grenadines",
  //         "currency_code": "XCD"
  //     },
  //     {
  //         "country": "Samoa",
  //         "currency_code": "WST"
  //     },
  //     {
  //         "country": "San Marino",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Sao Tome and Principe",
  //         "currency_code": "STD"
  //     },
  //     {
  //         "country": "Saudi Arabia",
  //         "currency_code": "SAR"
  //     },
  //     {
  //         "country": "Scotland",
  //         "currency_code": "GBP"
  //     },
  //     {
  //         "country": "Senegal",
  //         "currency_code": "XOF"
  //     },
  //     {
  //         "country": "Serbia",
  //         "currency_code": "RSD"
  //     },
  //     {
  //         "country": "Seychelles",
  //         "currency_code": "SCR"
  //     },
  //     {
  //         "country": "Sierra Leone",
  //         "currency_code": "SLL"
  //     },
  //     {
  //         "country": "Singapore",
  //         "currency_code": "SGD"
  //     },
  //     {
  //         "country": "Slovakia",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Slovenia",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Solomon Islands",
  //         "currency_code": "SBD"
  //     },
  //     {
  //         "country": "Somalia",
  //         "currency_code": "SOS"
  //     },
  //     {
  //         "country": "South Africa",
  //         "currency_code": "ZAR"
  //     },
  //     {
  //         "country": "South Georgia and the South Sandwich Islands",
  //         "currency_code": "GBP"
  //     },
  //     {
  //         "country": "South Korea",
  //         "currency_code": "KRW"
  //     },
  //     {
  //         "country": "South Sudan",
  //         "currency_code": "SSP"
  //     },
  //     {
  //         "country": "Spain",
  //         "currency_code": "EUR"
  //     },
  //     {
  //         "country": "Sri Lanka",
  //         "currency_code": "LKR"
  //     },
  //     {
  //         "country": "Sudan",
  //         "currency_code": "SDG"
  //     },
  //     {
  //         "country": "Suriname",
  //         "currency_code": "SRD"
  //     },
  //     {
  //         "country": "Svalbard and Jan Mayen",
  //         "currency_code": "NOK"
  //     },
  //     {
  //         "country": "Swaziland",
  //         "currency_code": "SZL"
  //     },
  //     {
  //         "country": "Sweden",
  //         "currency_code": "SEK"
  //     },
  //     {
  //         "country": "Switzerland",
  //         "currency_code": "CHF"
  //     },
  //     {
  //         "country": "Syria",
  //         "currency_code": "SYP"
  //     },
  //     {
  //         "country": "Tajikistan",
  //         "currency_code": "TJS"
  //     },
  //     {
  //         "country": "Tanzania",
  //         "currency_code": "TZS"
  //     },
  //     {
  //         "country": "Thailand",
  //         "currency_code": "THB"
  //     },
  //     {
  //         "country": "The Democratic Republic of Congo",
  //         "currency_code": "CDF"
  //     },
  //     {
  //         "country": "Togo",
  //         "currency_code": "XOF"
  //     },
  //     {
  //         "country": "Tokelau",
  //         "currency_code": "NZD"
  //     },
  //     {
  //         "country": "Tonga",
  //         "currency_code": "TOP"
  //     },
  //     {
  //         "country": "Trinidad and Tobago",
  //         "currency_code": "TTD"
  //     },
  //     {
  //         "country": "Tunisia",
  //         "currency_code": "TND"
  //     },
  //     {
  //         "country": "Turkey",
  //         "currency_code": "TRY"
  //     },
  //     {
  //         "country": "Turkmenistan",
  //         "currency_code": "TMT"
  //     },
  //     {
  //         "country": "Turks and Caicos Islands",
  //         "currency_code": "USD"
  //     },
  //     {
  //         "country": "Tuvalu",
  //         "currency_code": "AUD"
  //     },
  //     {
  //         "country": "Uganda",
  //         "currency_code": "UGX"
  //     },
  //     {
  //         "country": "Ukraine",
  //         "currency_code": "UAH"
  //     },
  //     {
  //         "country": "United Arab Emirates",
  //         "currency_code": "AED"
  //     },
  //     {
  //         "country": "United Kingdom",
  //         "currency_code": "GBP"
  //     },
  //     {
  //         "country": "United States",
  //         "currency_code": "USD"
  //     },
  //     {
  //         "country": "United States Minor Outlying Islands",
  //         "currency_code": "USD"
  //     },
  //     {
  //         "country": "Uruguay",
  //         "currency_code": "UYU"
  //     },
  //     {
  //         "country": "Uzbekistan",
  //         "currency_code": "UZS"
  //     },
  //     {
  //         "country": "Vanuatu",
  //         "currency_code": "VUV"
  //     },
  //     {
  //         "country": "Venezuela",
  //         "currency_code": "VEF"
  //     },
  //     {
  //         "country": "Vietnam",
  //         "currency_code": "VND"
  //     },
  //     {
  //         "country": "Virgin Islands, British",
  //         "currency_code": "USD"
  //     },
  //     {
  //         "country": "Virgin Islands, U.S.",
  //         "currency_code": "USD"
  //     },
  //     {
  //         "country": "Wales",
  //         "currency_code": "GBP"
  //     },
  //     {
  //         "country": "Wallis and Futuna",
  //         "currency_code": "XPF"
  //     },
  //     {
  //         "country": "Western Sahara",
  //         "currency_code": "MAD"
  //     },
  //     {
  //         "country": "Yemen",
  //         "currency_code": "YER"
  //     },
  //     {
  //         "country": "Zambia",
  //         "currency_code": "ZMW"
  //     },
  //     {
  //         "country": "Zimbabwe",
  //         "currency_code": "ZWD"
  //     }
  // ]

  countryCodeWithName: any = [
    {
      name: 'Afghanistan',
      dial_code: '+93',
      code: 'AF',
    },
    {
      name: 'Aland Islands',
      dial_code: '+358',
      code: 'AX',
    },
    {
      name: 'Albania',
      dial_code: '+355',
      code: 'AL',
    },
    {
      name: 'Algeria',
      dial_code: '+213',
      code: 'DZ',
    },
    {
      name: 'AmericanSamoa',
      dial_code: '+1684',
      code: 'AS',
    },
    {
      name: 'Andorra',
      dial_code: '+376',
      code: 'AD',
    },
    {
      name: 'Angola',
      dial_code: '+244',
      code: 'AO',
    },
    {
      name: 'Anguilla',
      dial_code: '+1264',
      code: 'AI',
    },
    {
      name: 'Antarctica',
      dial_code: '+672',
      code: 'AQ',
    },
    {
      name: 'Antigua and Barbuda',
      dial_code: '+1268',
      code: 'AG',
    },
    {
      name: 'Argentina',
      dial_code: '+54',
      code: 'AR',
    },
    {
      name: 'Armenia',
      dial_code: '+374',
      code: 'AM',
    },
    {
      name: 'Aruba',
      dial_code: '+297',
      code: 'AW',
    },
    {
      name: 'Australia',
      dial_code: '+61',
      code: 'AU',
    },
    {
      name: 'Austria',
      dial_code: '+43',
      code: 'AT',
    },
    {
      name: 'Azerbaijan',
      dial_code: '+994',
      code: 'AZ',
    },
    {
      name: 'Bahamas',
      dial_code: '+1242',
      code: 'BS',
    },
    {
      name: 'Bahrain',
      dial_code: '+973',
      code: 'BH',
    },
    {
      name: 'Bangladesh',
      dial_code: '+880',
      code: 'BD',
    },
    {
      name: 'Barbados',
      dial_code: '+1246',
      code: 'BB',
    },
    {
      name: 'Belarus',
      dial_code: '+375',
      code: 'BY',
    },
    {
      name: 'Belgium',
      dial_code: '+32',
      code: 'BE',
    },
    {
      name: 'Belize',
      dial_code: '+501',
      code: 'BZ',
    },
    {
      name: 'Benin',
      dial_code: '+229',
      code: 'BJ',
    },
    {
      name: 'Bermuda',
      dial_code: '+1441',
      code: 'BM',
    },
    {
      name: 'Bhutan',
      dial_code: '+975',
      code: 'BT',
    },
    {
      name: 'Bolivia, Plurinational State of',
      dial_code: '+591',
      code: 'BO',
    },
    {
      name: 'Bosnia and Herzegovina',
      dial_code: '+387',
      code: 'BA',
    },
    {
      name: 'Botswana',
      dial_code: '+267',
      code: 'BW',
    },
    {
      name: 'Brazil',
      dial_code: '+55',
      code: 'BR',
    },
    {
      name: 'British Indian Ocean Territory',
      dial_code: '+246',
      code: 'IO',
    },
    {
      name: 'Brunei Darussalam',
      dial_code: '+673',
      code: 'BN',
    },
    {
      name: 'Bulgaria',
      dial_code: '+359',
      code: 'BG',
    },
    {
      name: 'Burkina Faso',
      dial_code: '+226',
      code: 'BF',
    },
    {
      name: 'Burundi',
      dial_code: '+257',
      code: 'BI',
    },
    {
      name: 'Cambodia',
      dial_code: '+855',
      code: 'KH',
    },
    {
      name: 'Cameroon',
      dial_code: '+237',
      code: 'CM',
    },
    {
      name: 'Canada',
      dial_code: '+1',
      code: 'CA',
    },
    {
      name: 'Cape Verde',
      dial_code: '+238',
      code: 'CV',
    },
    {
      name: 'Cayman Islands',
      dial_code: '+ 345',
      code: 'KY',
    },
    {
      name: 'Central African Republic',
      dial_code: '+236',
      code: 'CF',
    },
    {
      name: 'Chad',
      dial_code: '+235',
      code: 'TD',
    },
    {
      name: 'Chile',
      dial_code: '+56',
      code: 'CL',
    },
    {
      name: 'China',
      dial_code: '+86',
      code: 'CN',
    },
    {
      name: 'Christmas Island',
      dial_code: '+61',
      code: 'CX',
    },
    {
      name: 'Cocos (Keeling) Islands',
      dial_code: '+61',
      code: 'CC',
    },
    {
      name: 'Colombia',
      dial_code: '+57',
      code: 'CO',
    },
    {
      name: 'Comoros',
      dial_code: '+269',
      code: 'KM',
    },
    {
      name: 'Congo',
      dial_code: '+242',
      code: 'CG',
    },
    {
      name: 'Congo, The Democratic Republic of the Congo',
      dial_code: '+243',
      code: 'CD',
    },
    {
      name: 'Cook Islands',
      dial_code: '+682',
      code: 'CK',
    },
    {
      name: 'Costa Rica',
      dial_code: '+506',
      code: 'CR',
    },
    {
      name: "Cote d'Ivoire",
      dial_code: '+225',
      code: 'CI',
    },
    {
      name: 'Croatia',
      dial_code: '+385',
      code: 'HR',
    },
    {
      name: 'Cuba',
      dial_code: '+53',
      code: 'CU',
    },
    {
      name: 'Cyprus',
      dial_code: '+357',
      code: 'CY',
    },
    {
      name: 'Czech Republic',
      dial_code: '+420',
      code: 'CZ',
    },
    {
      name: 'Denmark',
      dial_code: '+45',
      code: 'DK',
    },
    {
      name: 'Djibouti',
      dial_code: '+253',
      code: 'DJ',
    },
    {
      name: 'Dominica',
      dial_code: '+1767',
      code: 'DM',
    },
    {
      name: 'Dominican Republic',
      dial_code: '+1849',
      code: 'DO',
    },
    {
      name: 'Ecuador',
      dial_code: '+593',
      code: 'EC',
    },
    {
      name: 'Egypt',
      dial_code: '+20',
      code: 'EG',
    },
    {
      name: 'El Salvador',
      dial_code: '+503',
      code: 'SV',
    },
    {
      name: 'Equatorial Guinea',
      dial_code: '+240',
      code: 'GQ',
    },
    {
      name: 'Eritrea',
      dial_code: '+291',
      code: 'ER',
    },
    {
      name: 'Estonia',
      dial_code: '+372',
      code: 'EE',
    },
    {
      name: 'Ethiopia',
      dial_code: '+251',
      code: 'ET',
    },
    {
      name: 'Falkland Islands (Malvinas)',
      dial_code: '+500',
      code: 'FK',
    },
    {
      name: 'Faroe Islands',
      dial_code: '+298',
      code: 'FO',
    },
    {
      name: 'Fiji',
      dial_code: '+679',
      code: 'FJ',
    },
    {
      name: 'Finland',
      dial_code: '+358',
      code: 'FI',
    },
    {
      name: 'France',
      dial_code: '+33',
      code: 'FR',
    },
    {
      name: 'French Guiana',
      dial_code: '+594',
      code: 'GF',
    },
    {
      name: 'French Polynesia',
      dial_code: '+689',
      code: 'PF',
    },
    {
      name: 'Gabon',
      dial_code: '+241',
      code: 'GA',
    },
    {
      name: 'Gambia',
      dial_code: '+220',
      code: 'GM',
    },
    {
      name: 'Georgia',
      dial_code: '+995',
      code: 'GE',
    },
    {
      name: 'Germany',
      dial_code: '+49',
      code: 'DE',
    },
    {
      name: 'Ghana',
      dial_code: '+233',
      code: 'GH',
    },
    {
      name: 'Gibraltar',
      dial_code: '+350',
      code: 'GI',
    },
    {
      name: 'Greece',
      dial_code: '+30',
      code: 'GR',
    },
    {
      name: 'Greenland',
      dial_code: '+299',
      code: 'GL',
    },
    {
      name: 'Grenada',
      dial_code: '+1473',
      code: 'GD',
    },
    {
      name: 'Guadeloupe',
      dial_code: '+590',
      code: 'GP',
    },
    {
      name: 'Guam',
      dial_code: '+1671',
      code: 'GU',
    },
    {
      name: 'Guatemala',
      dial_code: '+502',
      code: 'GT',
    },
    {
      name: 'Guernsey',
      dial_code: '+44',
      code: 'GG',
    },
    {
      name: 'Guinea',
      dial_code: '+224',
      code: 'GN',
    },
    {
      name: 'Guinea-Bissau',
      dial_code: '+245',
      code: 'GW',
    },
    {
      name: 'Guyana',
      dial_code: '+595',
      code: 'GY',
    },
    {
      name: 'Haiti',
      dial_code: '+509',
      code: 'HT',
    },
    {
      name: 'Holy See (Vatican City State)',
      dial_code: '+379',
      code: 'VA',
    },
    {
      name: 'Honduras',
      dial_code: '+504',
      code: 'HN',
    },
    {
      name: 'Hong Kong',
      dial_code: '+852',
      code: 'HK',
    },
    {
      name: 'Hungary',
      dial_code: '+36',
      code: 'HU',
    },
    {
      name: 'Iceland',
      dial_code: '+354',
      code: 'IS',
    },
    {
      name: 'India',
      dial_code: '+91',
      code: 'IN',
    },
    {
      name: 'Indonesia',
      dial_code: '+62',
      code: 'ID',
    },
    {
      name: 'Iran, Islamic Republic of Persian Gulf',
      dial_code: '+98',
      code: 'IR',
    },
    {
      name: 'Iraq',
      dial_code: '+964',
      code: 'IQ',
    },
    {
      name: 'Ireland',
      dial_code: '+353',
      code: 'IE',
    },
    {
      name: 'Isle of Man',
      dial_code: '+44',
      code: 'IM',
    },
    {
      name: 'Israel',
      dial_code: '+972',
      code: 'IL',
    },
    {
      name: 'Italy',
      dial_code: '+39',
      code: 'IT',
    },
    {
      name: 'Jamaica',
      dial_code: '+1876',
      code: 'JM',
    },
    {
      name: 'Japan',
      dial_code: '+81',
      code: 'JP',
    },
    {
      name: 'Jersey',
      dial_code: '+44',
      code: 'JE',
    },
    {
      name: 'Jordan',
      dial_code: '+962',
      code: 'JO',
    },
    {
      name: 'Kazakhstan',
      dial_code: '+77',
      code: 'KZ',
    },
    {
      name: 'Kenya',
      dial_code: '+254',
      code: 'KE',
    },
    {
      name: 'Kiribati',
      dial_code: '+686',
      code: 'KI',
    },
    {
      name: "Korea, Democratic People's Republic of Korea",
      dial_code: '+850',
      code: 'KP',
    },
    {
      name: 'Korea, Republic of South Korea',
      dial_code: '+82',
      code: 'KR',
    },
    {
      name: 'Kuwait',
      dial_code: '+965',
      code: 'KW',
    },
    {
      name: 'Kyrgyzstan',
      dial_code: '+996',
      code: 'KG',
    },
    {
      name: 'Laos',
      dial_code: '+856',
      code: 'LA',
    },
    {
      name: 'Latvia',
      dial_code: '+371',
      code: 'LV',
    },
    {
      name: 'Lebanon',
      dial_code: '+961',
      code: 'LB',
    },
    {
      name: 'Lesotho',
      dial_code: '+266',
      code: 'LS',
    },
    {
      name: 'Liberia',
      dial_code: '+231',
      code: 'LR',
    },
    {
      name: 'Libyan Arab Jamahiriya',
      dial_code: '+218',
      code: 'LY',
    },
    {
      name: 'Liechtenstein',
      dial_code: '+423',
      code: 'LI',
    },
    {
      name: 'Lithuania',
      dial_code: '+370',
      code: 'LT',
    },
    {
      name: 'Luxembourg',
      dial_code: '+352',
      code: 'LU',
    },
    {
      name: 'Macao',
      dial_code: '+853',
      code: 'MO',
    },
    {
      name: 'Macedonia',
      dial_code: '+389',
      code: 'MK',
    },
    {
      name: 'Madagascar',
      dial_code: '+261',
      code: 'MG',
    },
    {
      name: 'Malawi',
      dial_code: '+265',
      code: 'MW',
    },
    {
      name: 'Malaysia',
      dial_code: '+60',
      code: 'MY',
    },
    {
      name: 'Maldives',
      dial_code: '+960',
      code: 'MV',
    },
    {
      name: 'Mali',
      dial_code: '+223',
      code: 'ML',
    },
    {
      name: 'Malta',
      dial_code: '+356',
      code: 'MT',
    },
    {
      name: 'Marshall Islands',
      dial_code: '+692',
      code: 'MH',
    },
    {
      name: 'Martinique',
      dial_code: '+596',
      code: 'MQ',
    },
    {
      name: 'Mauritania',
      dial_code: '+222',
      code: 'MR',
    },
    {
      name: 'Mauritius',
      dial_code: '+230',
      code: 'MU',
    },
    {
      name: 'Mayotte',
      dial_code: '+262',
      code: 'YT',
    },
    {
      name: 'Mexico',
      dial_code: '+52',
      code: 'MX',
    },
    {
      name: 'Micronesia, Federated States of Micronesia',
      dial_code: '+691',
      code: 'FM',
    },
    {
      name: 'Moldova',
      dial_code: '+373',
      code: 'MD',
    },
    {
      name: 'Monaco',
      dial_code: '+377',
      code: 'MC',
    },
    {
      name: 'Mongolia',
      dial_code: '+976',
      code: 'MN',
    },
    {
      name: 'Montenegro',
      dial_code: '+382',
      code: 'ME',
    },
    {
      name: 'Montserrat',
      dial_code: '+1664',
      code: 'MS',
    },
    {
      name: 'Morocco',
      dial_code: '+212',
      code: 'MA',
    },
    {
      name: 'Mozambique',
      dial_code: '+258',
      code: 'MZ',
    },
    {
      name: 'Myanmar',
      dial_code: '+95',
      code: 'MM',
    },
    {
      name: 'Namibia',
      dial_code: '+264',
      code: 'NA',
    },
    {
      name: 'Nauru',
      dial_code: '+674',
      code: 'NR',
    },
    {
      name: 'Nepal',
      dial_code: '+977',
      code: 'NP',
    },
    {
      name: 'Netherlands',
      dial_code: '+31',
      code: 'NL',
    },
    {
      name: 'Netherlands Antilles',
      dial_code: '+599',
      code: 'AN',
    },
    {
      name: 'New Caledonia',
      dial_code: '+687',
      code: 'NC',
    },
    {
      name: 'New Zealand',
      dial_code: '+64',
      code: 'NZ',
    },
    {
      name: 'Nicaragua',
      dial_code: '+505',
      code: 'NI',
    },
    {
      name: 'Niger',
      dial_code: '+227',
      code: 'NE',
    },
    {
      name: 'Nigeria',
      dial_code: '+234',
      code: 'NG',
    },
    {
      name: 'Niue',
      dial_code: '+683',
      code: 'NU',
    },
    {
      name: 'Norfolk Island',
      dial_code: '+672',
      code: 'NF',
    },
    {
      name: 'Northern Mariana Islands',
      dial_code: '+1670',
      code: 'MP',
    },
    {
      name: 'Norway',
      dial_code: '+47',
      code: 'NO',
    },
    {
      name: 'Oman',
      dial_code: '+968',
      code: 'OM',
    },
    {
      name: 'Pakistan',
      dial_code: '+92',
      code: 'PK',
    },
    {
      name: 'Palau',
      dial_code: '+680',
      code: 'PW',
    },
    {
      name: 'Palestinian Territory, Occupied',
      dial_code: '+970',
      code: 'PS',
    },
    {
      name: 'Panama',
      dial_code: '+507',
      code: 'PA',
    },
    {
      name: 'Papua New Guinea',
      dial_code: '+675',
      code: 'PG',
    },
    {
      name: 'Paraguay',
      dial_code: '+595',
      code: 'PY',
    },
    {
      name: 'Peru',
      dial_code: '+51',
      code: 'PE',
    },
    {
      name: 'Philippines',
      dial_code: '+63',
      code: 'PH',
    },
    {
      name: 'Pitcairn',
      dial_code: '+872',
      code: 'PN',
    },
    {
      name: 'Poland',
      dial_code: '+48',
      code: 'PL',
    },
    {
      name: 'Portugal',
      dial_code: '+351',
      code: 'PT',
    },
    {
      name: 'Puerto Rico',
      dial_code: '+1939',
      code: 'PR',
    },
    {
      name: 'Qatar',
      dial_code: '+974',
      code: 'QA',
    },
    {
      name: 'Romania',
      dial_code: '+40',
      code: 'RO',
    },
    {
      name: 'Russia',
      dial_code: '+7',
      code: 'RU',
    },
    {
      name: 'Rwanda',
      dial_code: '+250',
      code: 'RW',
    },
    {
      name: 'Reunion',
      dial_code: '+262',
      code: 'RE',
    },
    {
      name: 'Saint Barthelemy',
      dial_code: '+590',
      code: 'BL',
    },
    {
      name: 'Saint Helena, Ascension and Tristan Da Cunha',
      dial_code: '+290',
      code: 'SH',
    },
    {
      name: 'Saint Kitts and Nevis',
      dial_code: '+1869',
      code: 'KN',
    },
    {
      name: 'Saint Lucia',
      dial_code: '+1758',
      code: 'LC',
    },
    {
      name: 'Saint Martin',
      dial_code: '+590',
      code: 'MF',
    },
    {
      name: 'Saint Pierre and Miquelon',
      dial_code: '+508',
      code: 'PM',
    },
    {
      name: 'Saint Vincent and the Grenadines',
      dial_code: '+1784',
      code: 'VC',
    },
    {
      name: 'Samoa',
      dial_code: '+685',
      code: 'WS',
    },
    {
      name: 'San Marino',
      dial_code: '+378',
      code: 'SM',
    },
    {
      name: 'Sao Tome and Principe',
      dial_code: '+239',
      code: 'ST',
    },
    {
      name: 'Saudi Arabia',
      dial_code: '+966',
      code: 'SA',
    },
    {
      name: 'Senegal',
      dial_code: '+221',
      code: 'SN',
    },
    {
      name: 'Serbia',
      dial_code: '+381',
      code: 'RS',
    },
    {
      name: 'Seychelles',
      dial_code: '+248',
      code: 'SC',
    },
    {
      name: 'Sierra Leone',
      dial_code: '+232',
      code: 'SL',
    },
    {
      name: 'Singapore',
      dial_code: '+65',
      code: 'SG',
    },
    {
      name: 'Slovakia',
      dial_code: '+421',
      code: 'SK',
    },
    {
      name: 'Slovenia',
      dial_code: '+386',
      code: 'SI',
    },
    {
      name: 'Solomon Islands',
      dial_code: '+677',
      code: 'SB',
    },
    {
      name: 'Somalia',
      dial_code: '+252',
      code: 'SO',
    },
    {
      name: 'South Africa',
      dial_code: '+27',
      code: 'ZA',
    },
    {
      name: 'South Sudan',
      dial_code: '+211',
      code: 'SS',
    },
    {
      name: 'South Georgia and the South Sandwich Islands',
      dial_code: '+500',
      code: 'GS',
    },
    {
      name: 'Spain',
      dial_code: '+34',
      code: 'ES',
    },
    {
      name: 'Sri Lanka',
      dial_code: '+94',
      code: 'LK',
    },
    {
      name: 'Sudan',
      dial_code: '+249',
      code: 'SD',
    },
    {
      name: 'Suriname',
      dial_code: '+597',
      code: 'SR',
    },
    {
      name: 'Svalbard and Jan Mayen',
      dial_code: '+47',
      code: 'SJ',
    },
    {
      name: 'Swaziland',
      dial_code: '+268',
      code: 'SZ',
    },
    {
      name: 'Sweden',
      dial_code: '+46',
      code: 'SE',
    },
    {
      name: 'Switzerland',
      dial_code: '+41',
      code: 'CH',
    },
    {
      name: 'Syrian Arab Republic',
      dial_code: '+963',
      code: 'SY',
    },
    {
      name: 'Taiwan',
      dial_code: '+886',
      code: 'TW',
    },
    {
      name: 'Tajikistan',
      dial_code: '+992',
      code: 'TJ',
    },
    {
      name: 'Tanzania, United Republic of Tanzania',
      dial_code: '+255',
      code: 'TZ',
    },
    {
      name: 'Thailand',
      dial_code: '+66',
      code: 'TH',
    },
    {
      name: 'Timor-Leste',
      dial_code: '+670',
      code: 'TL',
    },
    {
      name: 'Togo',
      dial_code: '+228',
      code: 'TG',
    },
    {
      name: 'Tokelau',
      dial_code: '+690',
      code: 'TK',
    },
    {
      name: 'Tonga',
      dial_code: '+676',
      code: 'TO',
    },
    {
      name: 'Trinidad and Tobago',
      dial_code: '+1868',
      code: 'TT',
    },
    {
      name: 'Tunisia',
      dial_code: '+216',
      code: 'TN',
    },
    {
      name: 'Turkey',
      dial_code: '+90',
      code: 'TR',
    },
    {
      name: 'Turkmenistan',
      dial_code: '+993',
      code: 'TM',
    },
    {
      name: 'Turks and Caicos Islands',
      dial_code: '+1649',
      code: 'TC',
    },
    {
      name: 'Tuvalu',
      dial_code: '+688',
      code: 'TV',
    },
    {
      name: 'Uganda',
      dial_code: '+256',
      code: 'UG',
    },
    {
      name: 'Ukraine',
      dial_code: '+380',
      code: 'UA',
    },
    {
      name: 'United Arab Emirates',
      dial_code: '+971',
      code: 'AE',
    },
    {
      name: 'United Kingdom',
      dial_code: '+44',
      code: 'GB',
    },
    {
      name: 'United States',
      dial_code: '+1',
      code: 'US',
    },
    {
      name: 'Uruguay',
      dial_code: '+598',
      code: 'UY',
    },
    {
      name: 'Uzbekistan',
      dial_code: '+998',
      code: 'UZ',
    },
    {
      name: 'Vanuatu',
      dial_code: '+678',
      code: 'VU',
    },
    {
      name: 'Venezuela, Bolivarian Republic of Venezuela',
      dial_code: '+58',
      code: 'VE',
    },
    {
      name: 'Vietnam',
      dial_code: '+84',
      code: 'VN',
    },
    {
      name: 'Virgin Islands, British',
      dial_code: '+1284',
      code: 'VG',
    },
    {
      name: 'Virgin Islands, U.S.',
      dial_code: '+1340',
      code: 'VI',
    },
    {
      name: 'Wallis and Futuna',
      dial_code: '+681',
      code: 'WF',
    },
    {
      name: 'Yemen',
      dial_code: '+967',
      code: 'YE',
    },
    {
      name: 'Zambia',
      dial_code: '+260',
      code: 'ZM',
    },
    {
      name: 'Zimbabwe',
      dial_code: '+263',
      code: 'ZW',
    },
  ];

  countryMapping: { [key: string]: CountryISO } = {
    'Afghanistan': CountryISO.Afghanistan,
    'Albania': CountryISO.Albania,
    'Algeria': CountryISO.Algeria,
    'Andorra': CountryISO.Andorra,
    'Angola': CountryISO.Angola,
    'Antigua and Barbuda': CountryISO.AntiguaAndBarbuda,
    'Argentina': CountryISO.Argentina,
    'Armenia': CountryISO.Armenia,
    'Australia': CountryISO.Australia,
    'Austria': CountryISO.Austria,
    'Azerbaijan': CountryISO.Azerbaijan,
    'Bahamas': CountryISO.Bahamas,
    'Bahrain': CountryISO.Bahrain,
    'Bangladesh': CountryISO.Bangladesh,
    'Barbados': CountryISO.Barbados,
    'Belarus': CountryISO.Belarus,
    'Belgium': CountryISO.Belgium,
    'Belize': CountryISO.Belize,
    'Benin': CountryISO.Benin,
    'Bhutan': CountryISO.Bhutan,
    'Bolivia': CountryISO.Bolivia,
    'Bosnia and Herzegovina': CountryISO.BosniaAndHerzegovina,
    'Botswana': CountryISO.Botswana,
    'Brazil': CountryISO.Brazil,
    'Brunei Darussalam': CountryISO.Brunei,
    'Bulgaria': CountryISO.Bulgaria,
    'Burkina Faso': CountryISO.BurkinaFaso,
    'Burundi': CountryISO.Burundi,
    'Cabo Verde': CountryISO.CapeVerde, // Corrected 'CaboVerde' to 'CapeVerde'
    'Cambodia': CountryISO.Cambodia,
    'Cameroon': CountryISO.Cameroon,
    'Canada': CountryISO.Canada,
    'Central African Republic': CountryISO.CentralAfricanRepublic,
    'Chad': CountryISO.Chad,
    'Chile': CountryISO.Chile,
    'China': CountryISO.China,
    'Colombia': CountryISO.Colombia,
    'Comoros': CountryISO.Comoros,
    // 'Congo, Republic of the':  CountryISO.Congo, // Use 'Congo' for both
    // 'Congo, Democratic Republic of the': CountryISO.DemocraticRepublicOfTheCongo, // Use exact name
    'Costa Rica': CountryISO.CostaRica,
    'Croatia': CountryISO.Croatia,
    'Cuba': CountryISO.Cuba,
    'Cyprus': CountryISO.Cyprus,
    'Czech Republic': CountryISO.CzechRepublic,
    'Denmark': CountryISO.Denmark,
    'Djibouti': CountryISO.Djibouti,
    'Dominica': CountryISO.Dominica,
    'Dominican Republic': CountryISO.DominicanRepublic,
    'Ecuador': CountryISO.Ecuador,
    'Egypt': CountryISO.Egypt,
    'El Salvador': CountryISO.ElSalvador,
    'Equatorial Guinea': CountryISO.EquatorialGuinea,
    'Eritrea': CountryISO.Eritrea,
    'Estonia': CountryISO.Estonia,
    // 'Eswatini': CountryISO.Eswatini,// Corrected to 'Eswatini'
    'Ethiopia': CountryISO.Ethiopia,
    'Fiji': CountryISO.Fiji,
    'Finland': CountryISO.Finland,
    'France': CountryISO.France,
    'Gabon': CountryISO.Gabon,
    'Gambia': CountryISO.Gambia,
    'Georgia': CountryISO.Georgia,
    'Germany': CountryISO.Germany,
    'Ghana': CountryISO.Ghana,
    'Greece': CountryISO.Greece,
    'Grenada': CountryISO.Grenada,
    'Guatemala': CountryISO.Guatemala,
    'Guinea': CountryISO.Guinea,
    'Guinea-Bissau': CountryISO.GuineaBissau,
    'Guyana': CountryISO.Guyana,
    'Haiti': CountryISO.Haiti,
    'Honduras': CountryISO.Honduras,
    'Hungary': CountryISO.Hungary,
    'Iceland': CountryISO.Iceland,
    'India': CountryISO.India,
    'Indonesia': CountryISO.Indonesia,
    'Iran': CountryISO.Iran,
    'Iraq': CountryISO.Iraq,
    'Ireland': CountryISO.Ireland,
    'Israel': CountryISO.Israel,
    'Italy': CountryISO.Italy,
    'Jamaica': CountryISO.Jamaica,
    'Japan': CountryISO.Japan,
    'Jordan': CountryISO.Jordan,
    'Kazakhstan': CountryISO.Kazakhstan,
    'Kenya': CountryISO.Kenya,
    'Kiribati': CountryISO.Kiribati,
    'Korea, North': CountryISO.NorthKorea,
    'Korea, South': CountryISO.SouthKorea,
    'Kosovo': CountryISO.Kosovo,
    'Kuwait': CountryISO.Kuwait,
    'Kyrgyzstan': CountryISO.Kyrgyzstan,
    'Laos': CountryISO.Laos,
    'Latvia': CountryISO.Latvia,
    'Lebanon': CountryISO.Lebanon,
    'Lesotho': CountryISO.Lesotho,
    'Liberia': CountryISO.Liberia,
    'Libya': CountryISO.Libya,
    'Liechtenstein': CountryISO.Liechtenstein,
    'Lithuania': CountryISO.Lithuania,
    'Luxembourg': CountryISO.Luxembourg,
    'Madagascar': CountryISO.Madagascar,
    'Malawi': CountryISO.Malawi,
    'Malaysia': CountryISO.Malaysia,
    'Maldives': CountryISO.Maldives,
    'Mali': CountryISO.Mali,
    'Malta': CountryISO.Malta,
    'Marshall Islands': CountryISO.MarshallIslands,
    'Mauritania': CountryISO.Mauritania,
    'Mauritius': CountryISO.Mauritius,
    'Mexico': CountryISO.Mexico,
    'Micronesia': CountryISO.Micronesia,
    'Moldova': CountryISO.Moldova,
    'Monaco': CountryISO.Monaco,
    'Mongolia': CountryISO.Mongolia,
    'Montenegro': CountryISO.Montenegro,
    'Morocco': CountryISO.Morocco,
    'Mozambique': CountryISO.Mozambique,
    'Myanmar': CountryISO.Myanmar,
    'Namibia': CountryISO.Namibia,
    'Nauru': CountryISO.Nauru,
    'Nepal': CountryISO.Nepal,
    'Netherlands': CountryISO.Netherlands,
    'New Zealand': CountryISO.NewZealand,
    'Nicaragua': CountryISO.Nicaragua,
    'Niger': CountryISO.Niger,
    'Nigeria': CountryISO.Nigeria,
    'North Macedonia':  CountryISO.Macedonia, // Use exact name
    'Norway': CountryISO.Norway,
    'Oman': CountryISO.Oman,
    'Pakistan': CountryISO.Pakistan,
    'Palau': CountryISO.Palau,
    'Panama': CountryISO.Panama,
    'Papua New Guinea': CountryISO.PapuaNewGuinea,
    'Paraguay': CountryISO.Paraguay,
    'Peru': CountryISO.Peru,
    'Philippines': CountryISO.Philippines,
    'Poland': CountryISO.Poland,
    'Portugal': CountryISO.Portugal,
    'Qatar': CountryISO.Qatar,
    'Romania': CountryISO.Romania,
    'Russia': CountryISO.Russia,
    'Rwanda': CountryISO.Rwanda,
    'Saint Kitts and Nevis': CountryISO.SaintKittsAndNevis,
    'Saint Lucia': CountryISO.SaintLucia,
    'Saint Vincent and the Grenadines': CountryISO.SaintVincentAndTheGrenadines,
    'Samoa': CountryISO.Samoa,
    'San Marino': CountryISO.SanMarino,
    'São Tomé and Príncipe': CountryISO.SãoToméAndPríncipe, // Use 'SaoTomeAndPrincipe'
    'Saudi Arabia': CountryISO.SaudiArabia,
    'Senegal': CountryISO.Senegal,
    'Serbia': CountryISO.Serbia,
    'Seychelles': CountryISO.Seychelles,
    'Sierra Leone': CountryISO.SierraLeone,
    'Singapore': CountryISO.Singapore,
    'Slovakia': CountryISO.Slovakia,
    'Slovenia': CountryISO.Slovenia,
    'Solomon Islands': CountryISO.SolomonIslands,
    'Somalia': CountryISO.Somalia,
    'South Africa': CountryISO.SouthAfrica,
    'South Sudan': CountryISO.SouthSudan,
    'Spain': CountryISO.Spain,
    'Sri Lanka': CountryISO.SriLanka,
    'Sudan': CountryISO.Sudan,
    'Suriname': CountryISO.Suriname,
    'Sweden': CountryISO.Sweden,
    'Switzerland': CountryISO.Switzerland,
    'Syria': CountryISO.Syria,
    'Taiwan': CountryISO.Taiwan,
    'Tajikistan': CountryISO.Tajikistan,
    'Tanzania': CountryISO.Tanzania,
    'Thailand': CountryISO.Thailand,
    'Timor-Leste': CountryISO.TimorLeste,
    'Togo': CountryISO.Togo,
    'Tonga': CountryISO.Tonga,
    'Trinidad and Tobago': CountryISO.TrinidadAndTobago,
    'Tunisia': CountryISO.Tunisia,
    'Turkey': CountryISO.Turkey,
    'Turkmenistan': CountryISO.Turkmenistan,
    'Tuvalu': CountryISO.Tuvalu,
    'Uganda': CountryISO.Uganda,
    'Ukraine': CountryISO.Ukraine,
    'United Arab Emirates': CountryISO.UnitedArabEmirates,
    'United Kingdom': CountryISO.UnitedKingdom,
    'United States': CountryISO.UnitedStates,
    'Uruguay': CountryISO.Uruguay,
    'Uzbekistan': CountryISO.Uzbekistan,
    'Vanuatu': CountryISO.Vanuatu,
    'Vatican City': CountryISO.VaticanCity,
    'Venezuela': CountryISO.Venezuela,
    'Vietnam': CountryISO.Vietnam,
    'Yemen': CountryISO.Yemen,
    'Zambia': CountryISO.Zambia,
    'Zimbabwe': CountryISO.Zimbabwe
  };
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModuleRoutingModule } from './shared-module-routing.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';


@NgModule({
  declarations: [
    SidebarComponent,
    DashboardComponent,
    HeaderComponent
  ],
  imports: [
    CommonModule,
    SharedModuleRoutingModule,
  ],
  exports: [DashboardComponent, SidebarComponent, HeaderComponent]
})
export class SharedModuleModule {
  constructor() {
  }
}

import { AfterViewInit, ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastEvokeService } from '@costlydeveloper/ngx-awesome-popup';
import { CommonServiceService } from 'src/app/Services/common-service.service';
import { TempDataStorageService } from 'src/app/Services/temp-data-storage.service';
import * as moment from 'moment';
declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  dashboardCount: any;
  loader: boolean = true;
  subscribeVal: any
  startDate12: any = moment().format("DD/MM/YYYY")
  endDate12: any = moment().format("DD/MM/YYYY")
  constructor(private service: CommonServiceService, private zone: NgZone, private toster: ToastEvokeService, private router: Router, private tempdata: TempDataStorageService, private cd: ChangeDetectorRef) { }
  ngOnInit(): void {
    this.dateSet()
    this.getDashnoardCount();
    // this.getPackageWiseUser();
    this.getPackageWiseExpireData();
    this.subscribeData();
    this.getCountsSubscriptionCounts({ "startDate": this.startDate12, "endDate": this.endDate12 });
    this.getPackageWiseusers({ "startDate": this.startDate12, "endDate": this.endDate12 });
    (window as any)["packagedetailsData"] = (data: any, name: any) => {
      localStorage.setItem("packageName", name);
      // this.tempdata.packageName = name
      this.zone.run(() => {
        this.router.navigateByUrl(`superadmin/user/${data}/0`)
      });
    }
    (window as any)["Packageexpier"] = (data: any, type: any) => {
      this.zone.run(() => {
        this.router.navigateByUrl(`superadmin/user/${data}/${type}`)
      });
    }
  }
  async subscribeData() {
    this.service.dashboardSubscribeCount().subscribe((res: any) => {
      if (res.ErrorCode == 200) {
        this.subscribeVal = res.GetAllUsers
      } else {
        this.toster.warning('Subscribe Count ', res.ErrorMessage).subscribe();

      }
    }, (error) => {
      this.loader = false;

    })
  }


  async getDashnoardCount() {
    await this.service.DashboardUserCount().subscribe((res: any) => {
      if (res.ErrorCode == 200) {
        this.loader = false;
        this.dashboardCount = res.DashboardUserCount;
      } else {
        this.loader = false;
        this.toster.warning('', 'Something went wrong').subscribe();
      }
    }, (error) => {
      this.loader = false;

    })
  }

  async getPackageWiseUser() {
    await this.service.getpackageWiseData().subscribe((res: any) => {
      if (res.ErrorCode == 200) {
        this.PackageWiseUserDataTable(res.DashboardPackageCount)
        // this.PackageWiseUserDataTable(res.DashboardPackageCountnew)
      } else {
        this.toster.warning('Package wise user', res.ErrorMessage).subscribe();
      }
    }, (error) => {
      this.loader = false;

    })
  }

  PackageWiseUserDataTable = (data: any) => {
    let ismanagment = 1;
    let tableName = '';
    tableName = 'PackageWiseUsersTable';
    $(`#${tableName}`).DataTable({
      destroy: true,
      order: [[1, 'desc']],
      data: data,
      "bPaginate": false,
      scrollY: '300px',
      scrollCollapse: true,
      columns: [{ data: 'PackageName' }, { data: 'RenewPackagecount' }, {
        "data": "Count", "render": function (data: any, type: any, row: any, meta: any) {
          // return `<a class="btn btn-primary" style="padding: 5px 10px; font-size: 14px; min-width: 65px;"  onclick="window.packagedetailsData('${row.PackageId}','${row.PackageName}')" data=${data} >${data}</a>`
          return `<a class="ml-1 btn btn-sm btn_same1 btn_theme" onclick="window.packagedetailsData('${row.PackageId}','${row.PackageName}')" data=${data} >${data}</a>`
        }
      }],
      columnDefs: [
        {
          className: 'text-left', targets: [0]
        },
        {
          className: 'text-center', targets: [-2]
        },
        {
          className: 'text-center', targets: [-1]
        }
      ],
    });
  }

  // async getPackageWiseExpireData() {
  //   await this.service.getDataByPackageExpire().subscribe((res: any) => {
  //     if (res.ErrorCode == 200) {
  //       this.PackageExpierDataTable(res.getUserCountOfPackageexpier)
  //     } else {
  //       this.PackageExpierDataTable(res.getUserCountOfPackageexpier)
  //       this.toster.warning('Package wise expire info', res.ErrorMessage).subscribe();
  //     }
  //   }, (error) => {
  //     this.loader = false;

  //   })
  // }
  async getPackageWiseExpireData() {
    await this.service.getDataByPackageExpireNew().subscribe((res: any) => {
      if (res.ErrorCode == 200) {
        this.PackageExpierDataTable(res.getUserCountOfPackageexpierNew)
      } else {
        this.PackageExpierDataTable(res.getUserCountOfPackageexpierNew)
        this.toster.warning('Package wise expire info', res.ErrorMessage).subscribe();
      }
    }, (error) => {
      this.loader = false;

    })
  }



  PackageExpierDataTable = (data: any) => {
    let ismanagment = 1;
    let tableName = '';
    tableName = 'CountOfPackageexpier';
    let columns = [{ data: 'PackageName' },
    {
      data: 'TodayCount',
      "render": function (data: any, type: any, row: any, meta: any) {
        //return `<a class="btn btn-primary" style="padding: 5px 10px; font-size: 14px; min-width: 65px;"  onclick="window.Packageexpier('${row.PackageId}','${'today'}')" data=${data} datas="today" >${data}</a>`
        return `<a class="ml-1 btn btn-sm btn_same1 btn_theme" onclick="window.Packageexpier('${row.PackageId}','${'today'}')" data=${data} datas="today" >${data}</a>`
        // <a class="btn btn-danger" style="margin-left:5px; margin-right:-15x" onclick=window.deletedetail($(this).attr('data')) data=${data}>Delete</a>
      }
    },
    {
      "data": "WeekCount", "render": function (data: any, type: any, row: any, meta: any) {
        //return `<a class="btn btn-primary" style="padding: 5px 10px; font-size: 14px; min-width: 65px;"  onclick="window.Packageexpier('${row.PackageId}','${'week'}')" data=${data} datas="week">${data}</a>`
        return `<a class="ml-1 btn btn-sm btn_same1 btn_theme" onclick="window.Packageexpier('${row.PackageId}','${'week'}')" data=${data} datas="week">${data}</a>`
        // <a class="btn btn-danger" style="margin-left:5px; margin-right:-15x" onclick=window.deletedetail($(this).attr('data')) data=${data}>Delete</a>
      }
    },];
    $(`#${tableName}`).DataTable({
      destroy: true,
      order: [[1, 'desc']],
      data: data,
      "bPaginate": false,
      scrollY: '300px',
      scrollCollapse: true,
      columns: columns,
      columnDefs: [
        {
          className: 'text-left', targets: [0]
        },
        {
          className: 'text-right', targets: [-2]
        },
        {
          className: 'text-center', targets: [-1]
        }
      ],
    });
  }

  clcikCard(type: any) {
    switch (type) {
      case 'newUser':
        if (this.dashboardCount?.todayusercount > 0) {
          this.routeUrl(type);
        } else { this.showError('New User ') }

        break;
      case 'cureentWeekUser':
        if (this.dashboardCount?.weekusercount > 0) {
          this.routeUrl(type);
        } else { this.showError('Cureent Week User ') }

        break;
      case 'cureentMonthUser':
        if (this.dashboardCount?.monthusercount > 0) {
          this.routeUrl(type);
        } else { this.showError('Cureent Month User ') }

        break;
      case 'activeUser':
        if (this.dashboardCount?.totalactiveusercount > 0) {
          this.routeUrl(type);
        } else { this.showError('Active User ') }

        break;
      case 'deactiveUser':
        if (this.dashboardCount?.totaldeactiveusercount > 0) {
          this.routeUrl(type);
        } else { this.showError('Deactive User ') }

        break;
      case 'totalUser':
        if (this.dashboardCount?.totalusercount > 0) {
          this.routeUrl(type);
        } else { this.showError('Total User ') }

        break;

    }





  }

  routeUrl(type: any) {
    this.router.navigate([`/superadmin/user/${type}`]);
  }

  showError(type: any) {
    this.toster.warning('Warning', `${type} count is zero`).subscribe();
  }

  dateForamt: any = { start: '', end: '' }
  dateSet() {


    $(document).ready(() => {
      const obj = {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        ThisWeek: [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
        ThisMonth: [moment().startOf('month'), moment().endOf('month')],
      };

      $("input[name='subscriptionDateTimes']").daterangepicker({
        startDate: moment(), // default start date
        endDate: moment(), // default end date
        format: 'DD-MM-YYYY',
        ranges: obj,
        locale: {
          format: 'DD/MM/YYYY'
        }
      }, (start: any, end: any, label: any) => {
        let setData = {
          startDate: start.format("DD/MM/YYYY"),
          endDate: end.format("DD/MM/YYYY")
        }
        this.getCountsSubscriptionCounts(setData)

        // Ensure `this` context refers to the correct object
        this.startDate12 = start.format("DD/MM/YYYY");
        this.endDate12 = end.format("DD/MM/YYYY");
      });
      $("input[name='packageWishDateTimes']").daterangepicker({
        startDate: moment(), // default start date
        endDate: moment(), // default end date
        format: 'DD-MM-YYYY',
        ranges: obj,
        locale: {
          format: 'DD/MM/YYYY'
        }
      }, (start: any, end: any, label: any) => {
        let setData = {
          startDate: start.format("DD/MM/YYYY"),
          endDate: end.format("DD/MM/YYYY")
        }
        this.getPackageWiseusers(setData)

        // Ensure `this` context refers to the correct object
        this.startDate12 = start.format("DD/MM/YYYY");
        this.endDate12 = end.format("DD/MM/YYYY");
      });
      $("input[name='packageExpireInfoDateTimes']").daterangepicker({
        startDate: moment(), // default start date
        endDate: moment(), // default end date
        format: 'DD-MM-YYYY',
        ranges: obj,
        locale: {
          format: 'DD/MM/YYYY'
        }
      }, (start: any, end: any, label: any) => {
        // let setData = {
        //   startDate: start.format("DD/MM/YYYY"),
        //   endDate: end.format("DD/MM/YYYY")
        // }
        // this.getSubscriptionCounts(setData)

        // Ensure `this` context refers to the correct object
        this.startDate12 = start.format("DD/MM/YYYY");
        this.endDate12 = end.format("DD/MM/YYYY");
      });
    });
  }

  subscriptionCounts: any = {}
  async getCountsSubscriptionCounts(data: any) {
    this.service.getSubscriptionCounts(data).subscribe((res: any) => {
      if (res.ErrorCode == 200) {
        this.subscriptionCounts = res.userSubscriptionCounts
        this.cd.detectChanges();
      }
    }, (error) => {
      this.loader = false;

    })
  }
  async getPackageWiseusers(data: any) {

    await this.service.packageWiseDataNew(data).subscribe((res: any) => {
      if (res.ErrorCode == 200) {
        // this.PackageWiseUserDataTable(res.DashboardPackageCount)
        this.PackageWiseUserDataTable(res.DashboardPackageCountnew)

      } else {
        this.toster.warning('Package wise user', res.ErrorMessage).subscribe();
      }
    }, (error) => {
      this.loader = false;

    })
  }
  getDtartDate1(value: any) {
    $(function () {
      $('input[name="subscriptionRange"]').daterangepicker();
    });
  }

}




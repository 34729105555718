<!-- <?php include('include/header.php'); ?> -->
<div class="login">
  <div class="login_box1 card_me">
    <div class="login_box2 conten_set">
      <div class="text-center">
        <img src="assets/img/logo2.png" alt="" class="img_r logo_login">
      </div>
      <h2>
        Welcome Back !
        <span>Sign in to continue to business whatsup.</span>
      </h2>
      <form [formGroup]="loginForm">
        <div class="mb-3">
          <input type="text" class="form-control quantity" placeholder="Enter Email" formControlName="email"
            autocomplete="false" required>
          <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched )" class="text-danger valdi">
            <div *ngIf="f.email.errors?.required">Email is required </div>
            <div *ngIf="f.email.errors?.pattern">Email must be a valid email address</div>
          </div>
        </div>
        <div class="mb-3">
          <input type="password" class="form-control" placeholder="Enter Password" formControlName="code"
            autocomplete="false" required>
          <div *ngIf="f.code.invalid && (f.code.dirty || f.code.touched )" class="text-danger valdi">
            <div *ngIf="f.code.errors?.required">Password is required </div>
          </div>
        </div>
        <div class="form-group1">
          <button class="btn btn_theme btn-lg btn-block text_u" (click)="login()" [disabled]="loginbtn">Login <i class="fa fa-circle-o-notch fa-spin" *ngIf="loginbtn"></i></button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- <?php include('include/footer.php'); ?> -->

<div class="header">
  <div class="menu">
    <button class="btn btn_side" (click)="openSiddde()"><i class="fa fa-bars"></i></button>
  </div>
  <div class="right_mennu">
    <ul class="ul_set">
      <!-- <li>
        <div class="sarch_me icon_in">
          <i class="uil uil-search-alt"></i>
          <input type="text" class="form-control" placeholder="Search">
        </div>
      </li> -->
      <!-- <li>
        <a>
          <i class="uil uil-bell"></i>
        </a>
      </li> -->
      <li class="dropdown user_profile">
        <a role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"
          class="dropdown-toggle"><img src="assets/img/user.png" alt=""></a>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <!-- <li><a class="dropdown-item" ><i class="fa fa-user-o"></i> Profile</a></li> -->
          <li style="cursor: pointer;"><a class="dropdown-item" (click)="logOut()"><i class="fa fa-sign-out"></i>
              Logout</a></li>
          <li style="cursor: pointer;" *ngIf="loginDetails && loginDetails?.isSubadmin == 0"><a class="dropdown-item"  [routerLink]="['/tempCom/deafult-OTP']"><i class="fa fa-sign-out"></i>
                New</a></li>
        </ul>
      </li>
    </ul>
  </div>

</div>

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModuleModule } from './shared-module/shared-module.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';

// import { ConfirmBoxConfigModule, DialogConfigModule, NgxAwesomePopupModule, ToastNotificationConfigModule } from '@costlydeveloper/ngx-awesome-popup';
import {
  NgxAwesomePopupModule,
  ConfirmBoxConfigModule,
  DialogConfigModule,
  ToastNotificationConfigModule,
  ToastUserViewTypeEnum,
  AppearanceAnimation
} from '@costlydeveloper/ngx-awesome-popup';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CapitalizeFirstLetterPipe } from './pipeFiles/capitalize-first-letter.pipe';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CapitalizeFirstLetterPipe,
  ],

  imports: [
    BrowserModule,
    AppRoutingModule, HttpClientModule, FormsModule, ReactiveFormsModule,SharedModuleModule,
    NgxAwesomePopupModule.forRoot(),
    ConfirmBoxConfigModule.forRoot(),
    DialogConfigModule.forRoot(), // optional
    ToastNotificationConfigModule.forRoot(), NgbModule, NgxIntlTelInputModule // optional
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
  }
}

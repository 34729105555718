import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './shared-module/dashboard/dashboard.component';
import { AnalyticsComponent } from './shared-module/analytics/analytics.component';

import { AuthGuard } from './Auth-Gaurd/auth.guard';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  { path: '', component: LoginComponent },

  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },

  { path: 'analytics', component: AnalyticsComponent, canActivate: [AuthGuard] },

  {
    path: 'superadmin',
    loadChildren: () => import('src/app/super-admin-module/super-admin-module.module').then(m => m.default)
  },

  {
    path: 'tempCom',
    loadChildren: () => import('src/app/temp-comp-module/temp-comp-module.module').then(m => m.default)
  },
  {
    path: 'subAdmin',
    loadChildren: () => import('src/app/sub-admin/sub-admin.module').then(m => m.default)
  },

  { path: '**', redirectTo: 'dashboard' }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="sidebar">
  <div class="logo">
    <img src="assets/img/logo2.png" alt class="img_r">
  </div>
  <div class="adnin_name">
    <h4>{{roll==0?"Super Admin":"Sub Admin"}}</h4>
  </div>

  <ul class="ul_set scroller_list" *ngIf="roll==0">
    <!-- <li><a (click)="removePaginationCondition()" [routerLink]="['/dashboard']" routerLinkActive="active1" [routerLinkActiveOptions]={exact:true}><i
                              class="uil uil-dashboard"></i> Dashboard</a></li>
            <li><a (click)="removePaginationCondition()" [routerLink]="['/superadmin/user']" routerLinkActive="active1"
                        [routerLinkActiveOptions]={exact:true}><i class="uil uil-user-square"></i>Business User</a></li>
                        <li><a (click)="removePaginationCondition()" [routerLink]="['/superadmin/packagelist']" routerLinkActive="active1"><i class="uil uil-package"></i>
                        Packages</a></li>
            <li><a (click)="removePaginationCondition()" [routerLink]="['/superadmin/featuresList']" routerLinkActive="active1"><i
                              class="uil uil-apps"></i>Features</a></li>
            <li><a (click)="removePaginationCondition()" [routerLink]="['/superadmin/payment-type']" routerLinkActive="active1"><i
                              class="uil uil-bill"></i>Payment
                        Type</a></li>
            <li><a (click)="removePaginationCondition()" [routerLink]="['/superadmin/group']" routerLinkActive="active1"><i
                              class="uil uil-users-alt"></i>Group</a>
            </li>
            <li><a (click)="removePaginationCondition()" [routerLink]="['/superadmin/customer-list']" routerLinkActive="active1"><i
                              class="uil uil-user-plus"></i>Customer</a></li>
            <li><a (click)="removePaginationCondition()" [routerLink]="['/superadmin/contact-list']" routerLinkActive="active1"><i
                              class="uil uil-phone-alt"></i>Contact</a></li>
            <li><a (click)="removePaginationCondition()" [routerLink]="['/superadmin/terms&condition']" routerLinkActive="active1"><i
                  class="uil uil-pen"></i>Content</a></li>
            <li><a (click)="removePaginationCondition()" [routerLink]="['/superadmin/faq']" routerLinkActive="active1"><i
                  class="uil uil-question-circle"></i>FAQ's</a></li>

            <li><a (click)="removePaginationCondition()" [routerLink]="['/superadmin/admin-query']" routerLinkActive="active1"><i
                  class="uil uil-question-circle"></i>Admin Query</a></li>-->

    <li><a (click)="removePaginationCondition()"
        [class.active1]="pathUrl === '/dashboard'?true:false"
        [routerLink]="['/dashboard']"><i class="uil uil-dashboard"></i>
        Dashboard</a></li>
    <!-- <li><a (click)="removePaginationCondition()"
        [routerLink]="['/tempCom/Broadcast-in-Progress']"
        [class.active1]="this.pathUrl=='/tempCom/Broadcast-in-Progress'?true:false">
        <i class="uil uil-comment-upload"></i>Broadcast in Progress</a>
    </li> -->

    <li><a (click)="removePaginationCondition()"
        [class.active1]="pathUrl === '/superadmin/user' || pathUrl==='/superadmin/business-user-detail' || pathUrl==='/superadmin/package-history-details' || pathUrl==='/superadmin/chatbot-view' || pathUrl==='/superadmin/broadcast-details' || pathUrl === '/superadmin/user' || pathUrl==='features-send' ?true:false"
        [routerLink]="['/superadmin/user']">
        <i class="uil uil-user-square"></i>Business User </a></li>

        <li><a (click)="removePaginationCondition()"
            [routerLink]="['/superadmin/payment-failure']"
            [class.active1]="this.pathUrl=='/superadmin/payment-failure' || this.pathUrl=='/superadmin/payment-failure-detail' ?true:false">
            <i class="uil uil-user-square"></i>Payment Failure</a></li>

            <li><a (click)="removePaginationCondition()"
                [routerLink]="['/superadmin/package-expire']"
                [class.active1]="this.pathUrl=='/superadmin/package-expire'?true:false">
                <i class="uil uil-apps"></i>Package Expire User</a>
            </li>

    <li><a (click)="removePaginationCondition()"
        [routerLink]="['/superadmin/packagelist']"
        [class.active1]="this.pathUrl=='/superadmin/packagelist'?true:false">
        <i class="uil uil-package"></i>Packages</a></li>


    <li><a (click)="removePaginationCondition()"
        [routerLink]="['/superadmin/featuresList']"
        [class.active1]="this.pathUrl=='/superadmin/featuresList'?true:false">
        <i class="uil uil-apps"></i>Features</a>
    </li>
    <li><a (click)="removePaginationCondition()"
        [routerLink]="['/tempCom/module']"
        [class.active1]="this.pathUrl=='/tempCom/module' || this.pathUrl=='/tempCom/create-module' ||  this.pathUrl.startsWith('/tempCom/update-module/')?true:false">
        <i class="uil uil-apps"></i>Module</a>
    </li>
    <li><a (click)="removePaginationCondition()"
        [routerLink]="['/tempCom/menu']"
        [class.active1]="this.pathUrl=='/tempCom/menu' || pathUrl=='/tempCom/create-menu' || pathUrl.startsWith('/tempCom/update-menu/')?true:false">
        <i class="uil uil-apps"></i>Menu</a>
    </li>
    <li><a (click)="removePaginationCondition()"
        [routerLink]="['/tempCom/MenuPermission']"
        [class.active1]="this.pathUrl=='/tempCom/MenuPermission'?true:false">
        <i class="uil uil-apps"></i>Menu Permission</a>
    </li>

    <li><a (click)="removePaginationCondition()"
        [routerLink]="['/superadmin/payment-type']"
        [class.active1]="this.pathUrl=='/superadmin/payment-type' || this.pathUrl=='/superadmin/update-payment-type'">
        <i class="uil uil-bill"></i>Payment Type</a>
    </li>

    <!-- <li><a (click)="removePaginationCondition()"
        [routerLink]="['/superadmin/group']"
        [class.active1]="this.pathUrl=='/superadmin/group' || this.pathUrl=='/tempCom/userbygroup'?true:false">
        <i class="uil uil-users-alt"></i>Group</a>
    </li>

    <li><a (click)="removePaginationCondition()"
        [routerLink]="['/superadmin/customer-list']"
        [class.active1]="this.pathUrl=='/superadmin/customer-list'?true:false">
        <i class="uil uil-user-plus"></i>Customer</a>
    </li>

    <li><a (click)="removePaginationCondition()"
        [routerLink]="['/superadmin/contact-list']"
        [class.active1]="this.pathUrl=='/superadmin/contact-list'?true:false">
        <i class="uil uil-phone-alt"></i>Contact</a>
    </li> -->

    <!-- <li><a (click)="removePaginationCondition()" [routerLink]="['/tempCom/integration']" routerLinkActive="active1"><i
          class="uil uil-setting"></i>Integration</a></li> -->
    <li><a (click)="removePaginationCondition()"
        [routerLink]="['/superadmin/terms&condition']"
        [class.active1]="this.pathUrl=='/superadmin/terms&condition'?true:false"><i
          class="uil uil-pen"></i>Content</a>
    </li>

    <li><a (click)="removePaginationCondition()"
        [routerLink]="['/superadmin/faq']"
        [class.active1]="this.pathUrl=='/superadmin/faq'?true:false">
        <i class="uil uil-question-circle"></i>FAQ's</a>
    </li>

    <li><a (click)="removePaginationCondition()"
        [routerLink]="['/superadmin/admin-query']"
        [class.active1]="this.pathUrl=='/superadmin/admin-query' || this.pathUrl=='/superadmin/Super-admin-reply' ?true:false">
        <i class="uil uil-question-circle"></i>Admin Query</a>
    </li>
    <li><a (click)="removePaginationCondition()"
        [routerLink]="['/tempCom/maintenance']"
        [class.active1]="this.pathUrl=='/tempCom/maintenance' || this.pathUrl=='/tempCom/update_maintenance/:id' ?true:false">
        <i class="uil uil-apps"></i>Maintenance</a>
    </li>

    <li><a (click)="removePaginationCondition()"
        [routerLink]="['/superadmin/Error-log']"
        [class.active1]="this.pathUrl=='/superadmin/Error-log' ?true:false">
        <i class="uil uil-question-circle"></i>Error Log</a>
    </li>
    <li><a (click)="removePaginationCondition()"
        [routerLink]="['/superadmin/broadcast-shedule']"
        [class.active1]="this.pathUrl=='/superadmin/broadcast-shedule' ?true:false">
        <i class="uil uil-question-circle"></i>BroadCast Info</a>
    </li>
    <li><a (click)="removePaginationCondition()"
        [routerLink]="['/superadmin/email-container']"
        [class.active1]="this.pathUrl=='/superadmin/email-container' ?true:false">
        <i class="uil uil-question-circle"></i>Email Content</a>
    </li>
    <li><a (click)="removePaginationCondition()"
        [routerLink]="['/superadmin/user-package']"
        [class.active1]="this.pathUrl=='/superadmin/user-package' ?true:false">
        <i class="uil uil-question-circle"></i>User Package</a>
    </li>
    <li><a (click)="removePaginationCondition()"
        [routerLink]="['/superadmin/features-send-list']"
        [class.active1]="this.pathUrl=='/superadmin/features-send-list'?true:false">
        <i class="uil uil-apps"></i>Assign Features</a>
    </li>

    <li><a (click)="removePaginationCondition()"
        [routerLink]="['/superadmin/expire-token']"
        [class.active1]="this.pathUrl=='/superadmin/expire-token'?true:false">
        <i class="uil uil-apps"></i> Expire Token</a>
    </li>   
  </ul>

  <ul class="ul_set" *ngIf="roll==1">
    <li>
      <!-- <a [routerLink]="['subAdmin/dashboard']" routerLinkActive="active1"
                        [routerLinkActiveOptions]={exact:true}><i class="uil uil-dashboard"></i> Dashboard</a> -->
      <a [class.active1]="pathUrl === '/dashboard'?true:false"
        [routerLink]="['/dashboard']">
        <i class="uil uil-dashboard"></i> Dashboard</a>
    </li>
    <li>
      <!-- <a [routerLink]="['/subAdmin/business-user/']" routerLinkActive="active1"
                        [routerLinkActiveOptions]={exact:true}><i class="uil uil-user-square"></i>Business User</a> -->
      <a
        [class.active1]="pathUrl === '/superadmin/user' || pathUrl==='/superadmin/business-user-detail'?true:false"
        [routerLink]="['/superadmin/user']">
        <i class="uil uil-user-square"></i>Business User</a>
    </li>
  </ul>
  <ul class="ul_set" *ngIf="roll==2">
    <li>
      <a [routerLink]="['/superadmin/admin-query']"
        [class.active1]="this.pathUrl=='/superadmin/admin-query'?true:false">
        <i class="uil uil-question-circle"></i>Admin Query</a>
    </li>
  </ul>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonServiceService } from '../Services/common-service.service';
import { ToastEvokeService } from '@costlydeveloper/ngx-awesome-popup';
import { SidebarComponent } from '../shared-module/sidebar/sidebar.component';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: any = FormGroup
  loginbtn: boolean = false
  @ViewChild(SidebarComponent) side!: SidebarComponent;
  constructor(private router: Router, private fb: FormBuilder, private service: CommonServiceService, private toastEvokeService: ToastEvokeService) { }
  get f() { return this.loginForm.controls };

  ngOnInit(): void {
    this.service.setLoginDetailsValue(null)
    localStorage.clear();
    this.loginForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)])],
      code: ['', [Validators.required]]
    })
  }

  login() {
    if (this.loginForm.valid) {
      this.loginbtn = true;
      this.service.login(this.loginForm.value).subscribe((res: any) => {
        if (res.ErrorCode == 200) {     
          localStorage.setItem("_id", res.adminLogin[0]._id == undefined ? '' : res.adminLogin[0]._id);
          localStorage.setItem('possession', res.isSubadmin);
          //res.isSubadmin == 0 ? this.router.navigate(['/dashboard']) : this.router.navigate(['subAdmin/dashboard']); //old

          if(res.isSubadmin == 0){
            this.router.navigate(['/dashboard'])
          } else if(res.isSubadmin == 2) {
            this.router.navigate(['subAdmin/admin-query']);
          } else{
            this.router.navigate(['subAdmin/dashboard'])
          }        
          this.toastEvokeService.success('', 'Login Successfully').subscribe();
        } else {
          this.loginbtn = false;
          this.toastEvokeService.danger('', res.ErrorMessage).subscribe();
        }
      }, (error) => {
        this.loginbtn = false;
        this.toastEvokeService.danger('', error.error.ErrorMessage).subscribe();
      })
    } else {
      try {
        this.loginForm.get('email').markAsTouched();
        this.loginForm.get('code').markAsTouched();
        this.loginbtn = false;
      } catch (error) { }
    }
  }
}

import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastEvokeService } from '@costlydeveloper/ngx-awesome-popup';
import { CommonServiceService } from 'src/app/Services/common-service.service';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";


@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrl: './analytics.component.css'
})
export class AnalyticsComponent implements OnInit {
  loader: boolean = true;
  stripePercent: any
  razorpayPercent: any
  freePercent: any
  stripeFinalData: any = []
  razorpayFinalData: any = []

  constructor(private service: CommonServiceService, private zone: NgZone, private toster: ToastEvokeService, private router: Router, private cd: ChangeDetectorRef) { }
  ngOnInit(): void {
    this.dataForPipChart('')

  }

  dataForPipChart(data: any) {
    this.service.getDataForPipChart(data).subscribe(async (res: any) => {
      if (res.ErrorCode == 200) {

        let stripeData: any = []
        res.stripePackageDetails.map((item: any, index: any) => {
          let findIndex = stripeData.find((row: { packageId: any; }) => row.packageId === item.packageId);
          if (findIndex) {
            findIndex.count += 1
            let findIndex2 = this.stripeFinalData.find((row: { category: any; }) => row.category === item.packagemastersDetails.name);
            findIndex2.value += 1
          } else {
            stripeData.push({
              packageId: item.packageId,
              count: 1,
              packageName: item.packagemastersDetails.name
            })
            this.stripeFinalData.push({
              category: item.packagemastersDetails.name,
              value: 1
            })
          }
        })
        // this.stripeFinalData = stripeData
        let razorpayData: any = []
        res.razorpayPackageDetails.map((item: any, index: any) => {
          let findIndex = razorpayData.find((row: { packageId: any; }) => row.packageId === item.packageId);
          if (findIndex) {
            findIndex.count += 1
            let findIndex2 = this.razorpayFinalData.find((row: { category: any; }) => row.category === item.packagemastersDetails.name);
            findIndex2.value += 1
          } else {
            razorpayData.push({
              packageId: item.packageId,
              value: 1,
              packageName: item.packagemastersDetails.name
            })
            this.razorpayFinalData.push({
              category: item.packagemastersDetails.name,
              value: 1
            })
          }
        })


        let total = res.stripePackageDetails.length + res.razorpayPackageDetails.length + res.freePackageCount;


        const percentageA = (res.stripePackageDetails.length / total) * 100;
        const percentageB = (res.razorpayPackageDetails.length / total) * 100;
        const percentageC = (res.freePackageCount / total) * 100;
        this.stripePercent = percentageA.toFixed(2)
        this.razorpayPercent = percentageB.toFixed(2)
        this.freePercent = percentageC.toFixed(2)

        this.chartLoaded('1')

      }
    }, (error) => {
      this.loader = false;

    })
  }

  chartLoaded(data: any) {
    let root = am5.Root.new("chartdiv");

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    let container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.p100,
        height: am5.p100,
        layout: root.horizontalLayout
      })
    );

    // Create main chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    let chart = container.children.push(
      am5percent.PieChart.new(root, {
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        alignLabels: false
      })
    );

    series.labels.template.setAll({
      textType: "circular",
      radius: 4
    });
    series.ticks.template.set("visible", false);
    series.slices.template.set("toggleKey", "none");

    // add events
    series.slices.template.events.on("click", function (e:any) {
      selectSlice(e.target);
    });

    // Create sub chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    let subChart = container.children.push(
      am5percent.PieChart.new(root, {
        radius: am5.percent(50),
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    // Create sub series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    let subSeries: any = subChart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category"
      })
    );

    subSeries.data.setAll(this.subSeriesArray);
    subSeries.slices.template.set("toggleKey", "none");

    let selectedSlice: any;

    series.on("startAngle", function () {
      updateLines();
    });

    container.events.on("boundschanged", function () {
      root.events.once("frameended", function () {
        updateLines();
      })
    });

    function updateLines() {
      if (selectedSlice) {
        let startAngle = selectedSlice.get("startAngle");
        let arc = selectedSlice.get("arc");
        let radius = selectedSlice.get("radius");

        let x00 = radius * am5.math.cos(startAngle);
        let y00 = radius * am5.math.sin(startAngle);

        let x10 = radius * am5.math.cos(startAngle + arc);
        let y10 = radius * am5.math.sin(startAngle + arc);

        let subRadius = subSeries.slices.getIndex(0).get("radius");
        let x01 = 0;
        let y01 = -subRadius;

        let x11 = 0;
        let y11 = subRadius;

        let point00 = series.toGlobal({ x: x00, y: y00 });
        let point10 = series.toGlobal({ x: x10, y: y10 });

        let point01 = subSeries.toGlobal({ x: x01, y: y01 });
        let point11 = subSeries.toGlobal({ x: x11, y: y11 });

        line0.set("points", [point00, point01]);
        line1.set("points", [point10, point11]);
      }
    }

    // lines
    let line0 = container.children.push(
      am5.Line.new(root, {
        position: "absolute",
        stroke: root.interfaceColors.get("text"),
        strokeDasharray: [2, 2]
      })
    );
    let line1 = container.children.push(
      am5.Line.new(root, {
        position: "absolute",
        stroke: root.interfaceColors.get("text"),
        strokeDasharray: [2, 2]
      })
    );

    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll([
      {
        category: "Razorpay",
        value: this.razorpayPercent,
        subData: this.razorpayFinalData
        // subData: [{}]

      },
      {
        category: "Free",
        value: this.freePercent,
        subData: [
          { category: "A", value: this.freePercent },

        ]
      },

      {
        category: "Stripe",
        value: this.stripePercent,
        subData: this.stripeFinalData
        // subData: [{}]

      }

    ]);

    function selectSlice(slice: any) {
      selectedSlice = slice;
      let dataItem = slice.dataItem;
      let dataContext = dataItem.dataContext;

      if (dataContext) {
        let i = 0;
        subSeries.data.each(function (dataObject: any) {
          let dataObj = dataContext.subData[i];
          if (dataObj) {
            if (!subSeries.dataItems[i].get("visible")) {
              subSeries.dataItems[i].show();
            }
            subSeries.data.setIndex(i, dataObj);
          }
          else {
            subSeries.dataItems[i].hide();
          }

          i++;
        });
      }

      let middleAngle = slice.get("startAngle") + slice.get("arc") / 2;
      let firstAngle: any = series.dataItems[0].get("slice").get("startAngle");

      series.animate({
        key: "startAngle",
        to: firstAngle - middleAngle,
        duration: 1000,
        easing: am5.ease.out(am5.ease.cubic)
      });
      series.animate({
        key: "endAngle",
        to: firstAngle - middleAngle + 360,
        duration: 1000,
        easing: am5.ease.out(am5.ease.cubic)
      });
    }

    container.appear(1000, 10);

    series.events.on("datavalidated", function () {
      selectSlice(series.slices.getIndex(0));
    });
  }

  backDashboard() {
    this.router.navigate([`/dashboard`]);
  }


  subSeriesArray: any = [
    { category: "A", value: 0 },
    { category: "B", value: 0 },
    { category: "C", value: 0 },
    { category: "D", value: 0 },
    { category: "E", value: 0 },
    { category: "F", value: 0 },
    { category: "G", value: 0 },
    { category: "H", value: 0 },
    { category: "I", value: 0 },
    { category: "J", value: 0 },
    { category: "K", value: 0 },
    { category: "L", value: 0 },
    { category: "M", value: 0 },
    { category: "N", value: 0 },
    { category: "O", value: 0 },
    { category: "P", value: 0 },
    { category: "Q", value: 0 },
    { category: "R", value: 0 },
    { category: "S", value: 0 },
    { category: "T", value: 0 },
    { category: "U", value: 0 },
    { category: "V", value: 0 },
    { category: "W", value: 0 },
    { category: "X", value: 0 },
    { category: "Y", value: 0 },
    { category: "Z", value: 0 },
    { category: "AA", value: 0 },
    { category: "AB", value: 0 },
    { category: "AC", value: 0 },
    { category: "AD", value: 0 },
    { category: "AE", value: 0 },
    { category: "AF", value: 0 },
    { category: "AG", value: 0 },
    { category: "AH", value: 0 },
    { category: "AI", value: 0 },
    { category: "AJ", value: 0 },
    { category: "AK", value: 0 },
    { category: "AL", value: 0 },
    { category: "AM", value: 0 },
    { category: "AN", value: 0 },
    { category: "AO", value: 0 },
    { category: "AP", value: 0 },
    { category: "AQ", value: 0 },
    { category: "AR", value: 0 },
    { category: "AS", value: 0 },
    { category: "AT", value: 0 },
    { category: "AU", value: 0 },
    { category: "AV", value: 0 },
    { category: "AW", value: 0 },
    { category: "AX", value: 0 },
    { category: "AY", value: 0 },


  ]
}

export const environment = {
  production: true,
  frontEndBaseUrl:"https://stageapp.go4whatsup.com/",
  serverBaseUrl:"https://stageapi.go4whatsup.com:8002/",
  STORAGE_ID: '65201d6717069302b211b03a',
  BROADCAST_ID: '649d70291f6907fb0f73fb0b',
  ASSIGN_MANUAL_PACKAGE: '66fce2cc375da74ebc66cc8d',

  stripeIndiaPaymentGatewayID : '671cf7d5d473962471d1ee44',
  stripeDubaiPaymentGatewayID : '648b15ce2ded2cfbd4fe6bf2',
  razorpayPaymentGatewayID : '661e718ecb65fa7f0f5b0aba'
};

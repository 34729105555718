<style>
    .chat_modal {
        height: calc(100vh - 127px);
    }
</style>
<div class="loaderr2" *ngIf="loader">
    <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
<div class="wrap_us">
    <div class="conten_main conten_set">
        <div class="p-4">
            <h3 class="main_head">
                Analytics
                <div class="" style="display: inline-flex;">
                    <div class="search" style="display: inline-block; margin-left: 1280px;">
                        <div class="">              
                            <button class="ml-3 btn btn_theme2 btn_reset" (click)="backDashboard()">Back</button>
                        </div>
                    </div>          
                </div>
            </h3>
        </div>
        <div>
            <div id="chartdiv" style="width: 100%; height: 600px;"></div>

        </div>
    </div>
</div>